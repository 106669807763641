import { uid } from 'uid'

const initState = () => {
  return {
    account: {
      _id: '',
      email: '',
      phone: '',
      first_name: '',
      last_name: '',
      status: '',
      access_type: '',
    },
    shipping_address: [],
    saved_payments: [],
    active_devices: [],
    default_address: '',
    default_payment: '',
    flags: {
      repeat_shopper: false,
    },
    config: {
      otp_preference: '',
    },
    created_at: 0,
    defAddress: null,
    defPayment: null,
    hasPayment: false,
    hasAddress: false,
    hasDevices: false,
    hasAccount: false,
    ewalletBalance: [
      {
        balance: 0,
        token_id: '',
      },
    ],
  }
}

export const state = () => initState()

export const mutations = {
  set(state, params) {
    const keys = Object.keys(params)
    keys.forEach((key) => (state[key] = params[key]))
  },
  setBalance(state, params) {
    state.ewalletBalance.push(params)
  },
  resetBalance() {
    this.$cookie.set('get_balance', false)
    state.ewalletBalance = [
      {
        balance: 0,
        token_id: '',
      },
    ]
  },
  removeBalance(state, token_id) {
    const ewallet = state.ewalletBalance.findIndex(
      (ewallet) => ewallet.token_id === token_id
    )
    if (ewallet > -1)
      state.ewalletBalance = state.ewalletBalance.filter(
        (x) => x.token_id !== token_id
      )
  },
  reset(state) {
    Object.assign(state, initState())
  },
  add(state, pars) {
    state.cards.push(pars)
  },
  setOtpPref(state, par) {
    state.config.otp_preference = par
  },
  unlinkBalance(state, token_id) {
    delete state.ewalletBalance[token_id]
  },
}

export const actions = {
  async addPayment(ctx, pars) {
    await ctx.commit(
      'set',
      {
        btnSaveCardLoading: true,
      },
      { root: true }
    )

    const SHOPPERSVC = this.$config.SHOPPERSVC

    const app_id = this.$config.APP_ID
    const request_id = uid()

    const headers = {
      headers: {
        'X-App-ID': app_id,
        'X-Request-ID': request_id,
      },
    }

    return this.$axios.$post(`${SHOPPERSVC}/v1/shopper/payment`, pars, headers)
  },
  async get({ dispatch }, pars = null) {
    // console.log('Shopper GET loading')
    const SHOPPERSVC = this.$config.SHOPPERSVC
    await this.$axios
      .$get(`${SHOPPERSVC}/v1/shopper`)
      .then(async (res) => {
        // console.log('Shopper GET response', res.data)
        const shipping_address = await res.data.shipping_address.reverse()
        const newShopper = { ...res.data, shipping_address }
        // console.log('newShopper:', newShopper)
        await dispatch('set', newShopper)
      })
      .catch((err) => {
        console.error('Shopper GET error', err)
      })
    // console.log('Shopper GET done')
  },
  async setAccount(ctx, pars) {
    await ctx.commit(
      'set',
      {
        btnAccLoading: true,
      },
      { root: true }
    )
    console.log('update shopper:', pars)
    const SHOPPERSVC = this.$config.SHOPPERSVC
    return this.$axios.$put(`${SHOPPERSVC}/v1/shopper`, pars)
  },
  async setDefaultPayment(ctx, token_id) {
    const SHOPPERSVC = this.$config.SHOPPERSVC
    await this.$axios
      .$put(`${SHOPPERSVC}/v1/shopper/payment/set-default`, { token_id })
      .then((res) => {
        console.log('setDefaultPayment response', res)
      })
      .catch((err) => {
        console.error('setDefaultPayment error', err)
        ctx.commit('set', err)
      })
  },
  async getLatestBalance(ctx, token_id) {
    const SHOPPERSVC = this.$config.SHOPPERSVC
    const checkCookies = this.$cookie.get('get_balance')
    const stateDefault = ctx.state.ewalletBalance
    const ewallet = stateDefault.findIndex(
      (ewallet) => ewallet.token_id === token_id
    )
    console.log('checkCookies', checkCookies)
    if (ewallet === -1 || (!checkCookies && ewallet > -1)) {
      await this.$axios
        .$get(
          `${SHOPPERSVC}/v1/shopper/payment/latest_balance?token_id=${token_id}`
        )
        .then(async (res) => {
          console.log('getLatestBalance response', res)
          if (res.data?.balance >= 0) {
            ctx.commit('removeBalance', token_id)
            ctx.commit('setBalance', { token_id, balance: res.data?.balance })
            this.$cookie.set('get_balance', true, {
              path: '/',
              maxAge: 3600, // 1 hours lifetime
            })
          } else {
            console.warn('Ewallet no balance')
          }
          await ctx.commit(
            'set',
            {
              latestLoading: false,
            },
            { root: true }
          )
        })
        .catch((err) => {
          console.error('getLatestBalance error', err)
          ctx.commit('set', err)
        })
    } else {
      return true
    }
  },
  async setOtpPreference(ctx, preference) {
    console.log('preference:', preference)
    const SHOPPERSVC = this.$config.SHOPPERSVC
    await this.$axios
      .$put(`${SHOPPERSVC}/v1/shopper/otp_preference`, preference)
      .then((res) => {
        console.log('setOtpPreference response', res)
      })
      .catch((err) => {
        console.error('setOtpPreference error', err)
        ctx.commit('set', err)
      })
  },
  deletePayment(ctx, { id }) {
    const SHOPPERSVC = this.$config.SHOPPERSVC
    return this.$axios.$delete(
      `${SHOPPERSVC}/v1/shopper/payment?token_id=${id}`
    )
  },
  async set(ctx, user) {
    console.log('%c-> shopper.set user ', 'color:##DB2777;', user)
    const usrSavedPayment = await user.saved_payments
    const uDefPayment = await user.default_payment
    // console.warn('uDefPayment:', uDefPayment)
    const payments = await ctx.rootState.payment.methods
    // check default payment
    const defPayment = uDefPayment
      ? usrSavedPayment.find((x) => x.token_id === uDefPayment)
      : null

    // console.log('defPayment:', defPayment)

    const findPayment = defPayment?.type
      ? this.$getChild(defPayment.type, payments)
      : null

    console.log('%c-> shopper.set findPayment ', 'color:##DB2777;', findPayment)

    // check default address
    const defAddress =
      user.shipping_address.find(
        (x) => x.address_id === user.default_address
      ) || null
    // check account
    const hasAccount = user.account.email.length > 3
    console.log('%c-> shopper.set hasAccount ', 'color:##DB2777;', hasAccount)

    // check address
    const hasAddress =
      user.default_address.length > 0 &&
      user.shipping_address.length > 0 &&
      !!defAddress
    // check payment
    const hasPayment =
      uDefPayment.length > 0 &&
      usrSavedPayment.length > 0 &&
      findPayment !== null
    // check devices
    const hasDevices = user.active_devices.length > 0
    // reconstruct shopper
    const shopper = {
      ...user,
      hasAccount,
      hasPayment,
      hasAddress,
      hasDevices,
      defPayment,
      defAddress,
    }
    ctx.commit('set', shopper)
  },
  async activate(ctx, token) {
    const SHOPPERSVC = this.$config.SHOPPERSVC
    // misal token sYukr0nN
    return await this.$axios.$get(
      `${SHOPPERSVC}/v1/shopper/activate?token=${token}`
    )
  },
}
