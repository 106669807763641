export default async (context, locale) => {
  return await Promise.resolve({
    lang: 'Language',
    hello: 'Hello',
    free: 'free',
    phone_no: 'Phone number',
    input_phone: 'Enter your phone number',
    we_send_otp: `We'll send you a verification code via WhatsApp`,
    login_signup: 'Login or Sign Up',
    check: 'Check',
    list_merchant: 'Merchants',
    start_shopping: 'Start Shopping Now!',
    account_activated: 'Your Account is Activated!',
    you_saved: 'You saved',
    selected_discount: 'discount selected',
    selected_discounts: 'discounts selected',
    have_no_discount: `This brand doesn't have any voucher`,
    have_no_lucky: `Maybe it's not your lucky day, Please come back later!`,
    discounts_applied: 'discounts applied',
    discount_activated:
      'You get Rp15.000 discount. Get ready to shop and discover exciting deals.',
    continue: 'Continue',
    powered_by: 'Checkout Powered by',
    powered: 'Powered by',
    promo_code: 'Promo Code',
    tagline_secure: 'Secure Fast Checkout',
    tagline_reward: 'Earn Rewards and Redeem Voucher',
    tagline_track: 'Track All order in one single place',
    tagline_easy: 'Easily Checkout from any FLIK merchants',
    qty: 'Quantity',
    ship_fee: 'Total Shipping Fee',
    ship_disc: 'Shipping Discount',
    confirm: 'Confirm',
    confirm_otp: 'Confirm OTP',
    otp_sent_word: 'Enter the verification code we just sent via',
    otp_sent_to: 'to number',
    otp_resend: 'Resend in ',
    otp_getit: 'Didn’t get it?',
    otp_try: 'Try resend OTP to',
    seconds: 'seconds',
    change: 'Change',
    or: 'or',
    yes: 'yes',
    no: 'no',
    cancel: 'Cancel',
    credit_card: 'Credit Card',
    installment: 'Installment',
    choose_installment: 'Please choose installment type',
    installment_with: 'Installment with',
    installment_full: 'Full Payment',
    includes: 'includes',
    services_fee: 'interest and services fee',
    email: 'Email',
    full_name: 'Full Name',
    first_name: 'First Name',
    last_name: 'Last Name',
    account: 'Account',
    logout: 'Sign Out',
    logout_confirm: 'Sign Out from this account ?',
    addr: 'Address',
    ship_addr: 'Shipping Address',
    ship_addr_1: 'Address Line',
    ship_addr_2: '(Optional) Apartement, Unit, Floor, etc.',
    ship_me: 'Ship to',
    province: 'Province',
    city: 'City',
    post_code: 'Postal Code',
    save_addr: 'Save Address',
    current_loc: 'Use Current Location',
    enter_addr: 'Search your address or location',
    choose_from_map: 'Choose precise location from map',
    payment: 'Payment',
    pls_choose_payment: 'Please choose payment method',
    select_payment: 'Select Payment Method',
    select_pay: 'Payment Method',
    select_pay_def: 'Select payment method as default',
    select_courier: 'Select Courier',
    no_courier: 'No courier available',
    pls_change_address: 'Please change shipping address.',
    activate: 'Activate',
    choose: 'Choose',
    how_to: 'How To',
    pay_now: 'Pay Now',
    pay_agree: 'By clicking it you are agree with',
    and: 'and',
    terms: 'Terms',
    terms_cond: 'Terms & Conditions',
    privacy: 'Privacy',
    shipment: 'Shipment',
    day: 'day',
    days: 'days',
    week: 'week',
    weeks: 'weeks',
    hours: 'hours',
    sameday: 'same day',
    apply: 'Apply',
    validating: 'Validating',
    apply_voucher: 'Apply Voucher',
    apply_voucher_word_1: 'You can apply voucher code from',
    apply_voucher_word_2: 'to get more benefit!!',
    cart_expired: 'Cart not found',
    cart_empty: 'Cart not found',
    cart_invalid: 'Invalid Cart ID',
    item_out_stock: '(out of stock) please remove it manually',
    edit: 'Edit',
    delete: 'Delete',
    list_addr: 'Shipping Address',
    add_ship_addr: 'Add Shipping Address',
    edit_ship_addr: 'Edit Shipping Address',
    add_addr: 'Add new address',
    edit_addr: 'Edit address',
    delete_addr: 'Delete address',
    select_suggest: 'Please select suggested location',
    addr_info:
      'If marker changes, address fill automatically. Update the address afterwards if needed',
    postalcode_info: 'Click here to view suggestion.',
    postalcode_no_result:
      'No postal code matched. Click here to use typed postal code.',
    postalcode_no_data: 'No postal code suggestion',
    type_postal_manually: 'Postal code not available please type manually',
    fill_required: 'Please fill out required fields',
    del_confirm: 'Are you sure will delete this address?',
    del_addr_nb: `NB: You can't restore a deleted address.`,
    denied_loc: 'Access location denied, please allow access location',
    recepient_name: `Recipient's Name`,
    recepient_phone: `Recipient's Phone`,
    add_new_card: 'Add New Credit / Debit Card',
    back: 'Back',
    expired_on: 'Expired on',
    add_debit_bca: 'Add Debit Card BCA',
    card_number: 'Card Number',
    expiration_date: 'Expiration Date',
    months: 'months',
    month: 'Month',
    year: 'Year',
    activate_card: 'Activate Card',
    complete_pay_word: 'Please complete your payment',
    before: 'Before',
    scan_to_pay: 'Scan to pay',
    scan_to_gopay: 'Scan QRIS with Gopay',
    scan_qris: 'Scan QRIS to Pay',
    download_qr: 'Download QR Code',
    total_pay: 'Total Payment',
    howtopay: 'How To Pay',
    howto_activate: 'How to activate',
    order_details: 'Order Details',
    order_id: 'Order ID',
    info_above_sent: 'Information above will be sent to your email',
    pay_status: 'Payment Status',
    pay_complete: 'Payment Complete',
    pay_processed: 'Thank you, your payment has been successful processed.',
    pay_deadline: 'Payment Deadline',
    confirm_mail_sent: 'A confirmation email has been sent to',
    trx_id: 'Transaction ID',
    order_date: 'Order Date',
    amount: 'Amount',
    pay_type: 'Payment Type',
    pay_method: 'Payment Method',
    pay_failed: 'Payment Failed',
    pay_fail_msg: `We are not able to process your payment. Please try again or try another
    payment option.`,
    done: 'Done',
    dont_close_browser: 'Do Not Close your Tab or Browser!',
    pls_wait_redirected: `Please wait until you are redirected back to Shopify order page!`,
    pls_redirect_order:
      'Please wait until redirected. If you leave this page manually, order will failed.',
    redirecting_to: 'Redirecting to',
    redirect_in_otp: 'Redirecting to OTP in',
    copy: 'Copy',
    copied: 'Copied',
    va: 'Virtual Account',
    va_no: 'Virtual Account Number',
    proceed: 'Proceed',
    read_tnc: 'I have read and agree to the terms and conditions that apply',
    tnc_1: `Users can only connect OVO and Flik Accounts on the latest version of
    the OVO application (3.47.0 and above).`,
    tnc_2: `By connecting OVO and Flik Accounts, Users can make payments using OVO
    Cash in the Flik application.`,
    tnc_3: `In circumstances where the User wants to unlink the OVO Account from the
    Flik Account, the User can unlink the account relationship through the
    OVO application (Profile → Connected Application).`,
    tnc_4: `OVO is not responsible for errors or omissions made by users in the
    payment process with OVO which has been linked to the user's Flik
    account.`,
    tnc_5: `By connecting OVO and Flik accounts, users agree to all terms and
    conditions of linking OVO accounts with OVO partners, and recurring
    transactions.`,
    ovo_easier: `Easier transactions by connecting your OVO account. Make sure the phone
    number you enter is the number registered with OVO.`,
    shopee_easier: `Easier transactions by connecting your ShopeePay account. Make sure the
    phone number you enter is the number registered with ShopeePay.`,
    dana_easier: `Easier transactions by connecting your DANA account. Make sure the phone
    number you enter is the number registered with DANA.`,
    gopay_easier: `Easier transactions by connecting your GoPay account. Make sure the phone
    number you enter is the number registered with GoPay.`,
    linking_ewallet_s: `Linking Ewallet Success`,
    linking_ewallet_f: `Linking Ewallet Failed`,
    redirect_to_in: `Redirect to Cart in`,
    home: 'Home',
    open: 'Open',
    activate_debit: 'Activate Debit Card BCA',
    howto_activate_debit: 'How To Activate Debit Card BCA',
    debitbca_0: 'BCA Card with Mastercard Logo',
    debitbca_1: 'Make sure your debit card has Mastercard logo on it.',
    debitbca_2: 'Open BCA Mobile App',
    debitbca_3: `Log in to BCA Mobile, go to Akun Saya, and choose “Kontrol” menu. Swipe to activate Transaksi Debit Online and click OK. Make sure you have set the right mobile number and click Daftar.`,
    debitbca_li_1:
      'Open <b>BCA Mobile</b> app on your device and click Akun Saya menu',
    debitbca_li_b1: 'BCA Mobile app',
    debitbca_li_2: 'Choose <b>Kontrol</b> menu',
    debitbca_li_3: `Select the <b>registered number</b> and activate the
      <b> Transaksi Debit Online </b> button. Make sure your cellphone
      number is connected to BCA mobile &amp; has credit for sending OTP
      codes for online transactions.`,
    debitbca_li_4:
      'Make sure the number registered is correct and click <b>Daftar</b>.',
    debitbca_li_5: 'Congrats! You have activate your Debit Card BCA',
    howto_qr_gopay_1: 'Using Mobile Device',
    howto_qr_gopay_2: 'Open Gojek app on your device',
    howto_qr_gopay_3: `Download the QR Code above. Choose Pay and upload the QR Code`,
    howto_qr_gopay_4:
      'Double check your payment details and tap confirm and pay',
    howto_qr_gopay_5: 'Enter your PIN',
    howto_qr_gopay_6: 'Congrats! You have completed the payment process',
    howto_qr_shopee_1: 'Using Mobile Device',
    howto_qr_shopee_2: 'Open <b>Shopee</b> app on your device',
    howto_qr_shopee_3:
      'Download the QR Code above. Click Scan Icon and upload the QR Code',
    howto_qr_shopee_4:
      'Double check your payment details and tap Make an Order',
    howto_qr_shopee_5: 'Enter your PIN to complete the purchase',
    howto_qr_shopee_6: 'Transaction completed',
    howto_qr_dana_1: 'Using Mobile Device',
    howto_qr_dana_2: 'Open <b>DANA</b> app on your device',
    howto_qr_dana_3:
      'Download the QR Code above. Click Scan Icon and upload the QR Code',
    howto_qr_dana_4: 'Congrats! You have completed the payment process',
    howto_qr_mandiri_1: 'Using Mobile Device',
    howto_qr_mandiri_2: 'Open <b>Livin Mandiri Mobile</b> app on your device',
    howto_qr_mandiri_3: 'Choose QR Bayar and scan the QR Code above',
    howto_qr_mandiri_4:
      'Enter the amount to be paid then click the continue button',
    howto_qr_mandiri_5:
      'Make sure the data entered is correct. Then, click the submit button',
    howto_qr_mandiri_6: 'Enter your MPIN',
    howto_qr_mandiri_7: 'Congrats! You have completed the payment process',
    howto_qr_bca_1: 'Using Mobile Device',
    howto_qr_bca_2: 'Open <b>BCA Mobile</b> app on your device',
    howto_qr_bca_3: `Download the QR Code above. Open <b>M-BCA</b> menu, choose QRIS
    and upload the QR Code`,
    howto_qr_bca_4: `Check the recipient's name and enter the transaction value`,
    howto_qr_bca_5:
      'Enter your M-BCA PIN 5. Congrats! You have completed the payment process',
    howto_qr_bni_1: 'Using Mobile Device',
    howto_qr_bni_2: 'Open <b>BNI Mobile Banking</b> app on your device',
    howto_qr_bni_3:
      'Download the QR Code above. Click QRIS and upload the QR Code',
    howto_qr_bni_4:
      'Enter the amount to be paid then click the continue button',
    howto_qr_bni_5: 'Enter your Transaction Password',
    howto_qr_bni_6: 'Congrats! You have completed the payment process',
    howto_qr_jenius_1: 'Using Mobile Device',
    howto_qr_jenius_2: 'Open <b>Jenius Mobile</b> app on your device',
    howto_qr_jenius_3:
      'Download the QR Code above. Click QRIS and upload the QR Code',
    howto_qr_jenius_4:
      'Enter the amount to be paid then click the continue button',
    howto_qr_jenius_5: 'Enter your Transaction Password',
    howto_qr_jenius_6: 'Congrats! You have completed the payment process',
    pay_va_bca_1: 'Enter the BCA ATM Card',
    pay_va_bca_2: 'Enter PIN',
    pay_va_bca_3: 'Select menu "Transaksi Lainnya"',
    pay_va_bca_4: 'Select menu "Transfer"',
    pay_va_bca_5: 'Select menu "ke Rekening BCA Virtual Account',
    pay_va_bca_6:
      'Enter your Virtual Account Number example: 700701598855309526. Press "Benar" to continue',
    pay_va_bca_7:
      'On the confirmation page, make sure the payment details are correct such as VA No, Name, Company/Product and Billing Total, press "Benar" to continue',
    pay_va_bca_8: 'Press "Ya" if it is correct',
    pay_va_bca_9: 'Your transaction has been completed',
    add_note: 'Add Note',
    placeholder_note: 'Write Notes',
  })
}
