
export default {
  name: 'ShopperLayout',
  id: 'shopper',
  data() {
    return {
      fromPromo:
        this.$route.fullPath.includes('promo') ||
        this.$route.fullPath.includes('voucher')
          ? ' bg-white'
          : '',
    }
  },
  head() {
    return {
      bodyAttrs: {
        class: this.$device.isDesktop
          ? `desktop${this.fromPromo}`
          : `mobile${this.fromPromo}`,
      },
    }
  },
  computed: {
    cRoute() {
      return this.$route.name
    },
  },
  mounted() {
    console.warn(
      '%c--  isCustomDomain -> ',
      'background:#FF95ED;color:#000;padding:4px;',
      this.$isCustomDomain()
    )
    this.$openReplayTrack()

    // GA tracking
    this.$trackPageView()
    // End GA tracking
  },
}
