const initState = () => {
  return {
    last: {
      id: 'ORD3MO-246810',
      total_amount: 0,
    },
    list: [
      {
        payment_status: 'PENDING',
        total_amount: 212,
      },
    ],
    failedReason: '',
    redirUrl: '',
  }
}

export const state = () => initState()

export const mutations = {
  set(state, params) {
    const keys = Object.keys(params)
    keys.forEach((key) => (state[key] = params[key]))
  },
  reset(state) {
    Object.assign(state, initState())
  },
}

export const actions = {
  async getOrderByTrx(ctx, trxId) {
    const ORDERSVC = this.$config.ORDERSVC
    await this.$axios
      .$get(`${ORDERSVC}/v1/orders/shopper?includes[trx_id]=${trxId}`)
      .then((res) => {
        ctx.commit('set', {
          list: res.data,
          last: res.data[0],
        })
        ctx.commit(
          'payment/set',
          {
            lastTrxStatus: res.data[0]?.payment_status || 'PENDING',
            trxDate: res.data[0]?.created_at,
            // disable set order total because not valid amount if have discount
            // lastOrderTotal: res.data[0]?.total_amount || 212,
          },
          { root: true }
        )
      })
      .catch((err) => {
        console.error('error', err)
      })
  },
  async getOrder(_ctx, id) {
    const ORDERSVC = this.$config.ORDERSVC
    return await this.$axios.$get(`${ORDERSVC}/v1/orders/shopper/${id}`)
  },
  async trackOrder({ dispatch, commit }, id) {
    await dispatch('getOrder', id).then((res) => {
      if (res?.data?.id === id) {
        commit('set', {
          last: res.data,
        })
      }
    })
  },
  async getOrderById({ commit, dispatch, state, rootState }, id) {
    await dispatch('getOrder', id).then((res) => {
      if (res?.data) {
        const queryStr = {
          order_id: res.data?.id || '',
          status: res.data?.status || '',
          ordno: res.data?.id || '',
          crnm: res.data?.shipment?.courier || '',
          awb: res.data?.shipment?.awb || '',
          sid: res.data?.metadata?.store_id || '',
        }
        const qstr = this.$serialize(queryStr)
        // set last order by order ID
        // set query string for redirectiion
        commit('set', {
          last: res.data,
          redirUrl: qstr,
        })
        // set payment status
        commit(
          'payment/set',
          {
            lastTrxStatus: res.data?.payment_status,
            trxDate: res.data?.created_at,
            // disable set order total because not valid amount if have discount
            // lastOrderTotal: res.data?.total_amount,
          },
          { root: true }
        )
      }
    })
    if (state.last.payment_status === 'SUCCEEDED') {
      const cartRedirUrl = await rootState.cart.cartRedirUrl
      const orderRedirUrl = await rootState.order.redirUrl
      const redirUrl = cartRedirUrl + `?` + orderRedirUrl
      if (cartRedirUrl.length > 0) {
        commit('cart/resetCartPlatform', null, { root: true })
        commit('cart/resetRaw')
        console.log('redirUrl:', redirUrl)
        location.replace(redirUrl)
      } else {
        this.$router.push('/order-success')
      }
    }
  },
  async getLastOrder({ commit, dispatch }, id) {
    await dispatch('getOrder', id).then((res) => {
      if (res?.data) {
        const queryStr = {
          order_id: res.data?.id || '',
          status: res.data?.status || '',
          ordno: res.data?.id || '',
          crnm: res.data?.shipment?.courier || '',
          awb: res.data?.shipment?.awb || '',
          sid: res.data?.metadata?.store_id || '',
        }
        const qstr = this.$serialize(queryStr)
        // set last order by order ID
        // set query string for redirectiion
        commit('set', {
          last: res.data,
          redirUrl: qstr,
        })
        // set payment status
        commit(
          'payment/set',
          {
            lastTrxStatus: res.data?.payment_status,
            trxDate: res.data?.created_at,
            // disable set order total because not valid amount if have discount
            // lastOrderTotal: res.data?.total_amount,
          },
          { root: true }
        )
      }
    })
  },
}
