
export default {
  name: 'DefaultLayout',
  id: 'default',
  data() {
    return {
      isMerchant:
        this.$route.name === 'merchant' || this.$route.name === 'promo-detail'
          ? 'bg-white'
          : '',
    }
  },
  head() {
    return {
      bodyAttrs: {
        class: this.$device.isDesktop
          ? `desktop ${this.isMerchant}`
          : `mobile ${this.isMerchant}`,
      },
    }
  },
  computed: {
    addclass() {
      let moreclass = ''
      if (this.$device.isDesktop) moreclass = 'step-desktop'
      return moreclass
    },
  },
  created() {
    // console.log('default this is: ', this)
    // console.log('process.server', process.server)
    // console.log('process.client', process.client)
    // console.log('isDev: ', this.$nuxt.context.isDev)
    // console.log('APP_DEMO: ', this.$config.APP_DEMO)
    // console.log('isDesktop', this.$device.isDesktop)
  },
  mounted() {
    console.warn(
      '%c--  isCustomDomain -> ',
      'background:#FF95ED;color:#000;padding:4px;',
      this.$isCustomDomain()
    )

    this.$openReplayTrack()

    // GA tracking
    this.$trackPageView()
    // End GA tracking
  },
}
