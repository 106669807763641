// import HmacSHA256 from 'crypto-js/hmac-sha256'
import { uid } from 'uid'

const initState = () => {
  return {
    // redeemLoading: false,
    validateLoading: false,
    validate: {
      valid: false,
      tracking_id: '', // track_HrPPu6yFVDs=
      order: {
        amount: 0,
        discount_amount: 0,
        total_discount_amount: 0,
        total_amount: 0,
        applied_discount_amount: 0,
        total_applied_discount_amount: 0,
        metadata: {},
        customer_id: null,
        referrer_id: null,
      },
      id: '', // promo_QfPqno9jiEQygdIy2W9xTGmB
      name: '', // Discount 70% NewShopper
      banner: '', // Discount 70% for new Flik Shopper max 25K
      discount_amount: 0,
      applied_discount_amount: 0,
    },
    redemption: {
      id: '', // r_0bb93049c21475e97b
      object: '',
      date: '', // 2022-09-21T02:12:39.304Z
      customer_id: '', // cust_1AHPAa79UrZFy2DQGYDfUMIM
      tracking_id: '', // track_ZGPtmYcM+Mw=
      metadata: null,
      result: '', // SUCCESS
      order: {
        id: '', // ord_A1RiP0g166NpW4m47g8Ie407
        source_id: null,
        created_at: '', // 2022-09-21T02:12:39.269Z
        updated_at: null,
        status: '', // PAID
        amount: 0,
        discount_amount: 0,
        total_discount_amount: 0,
        total_amount: 0,
        applied_discount_amount: 0,
        total_applied_discount_amount: 0,
        customer_id: '', // cust_1AHPAa79UrZFy2DQGYDfUMIM
        referrer_id: null,
        object: 'order',
        redemptions: {},
      },
      voucher: null,
      promotion_tier: {},
    },
    list: [],
    totalDiscount: 0,
  }
}
// initial state
export const state = () => initState()

// mutations defined as object
export const mutations = {
  reset(state) {
    Object.assign(state, initState())
  },
  set(state, params) {
    const keys = Object.keys(params)
    keys.forEach((key) => (state[key] = params[key]))
  },
  resetValidate(state) {
    state.validateLoading = false
    state.validate = initState().validate
  },
  resetValidateOrder(state) {
    state.validateLoading = false
    state.validate.order = initState().validate.order
  },
  setTotalDiscount(state, par) {
    state.totalDiscount = par
  },
}

// actions
export const actions = {
  // Reset user states
  reset({ commit }) {
    commit('reset')
  },
  set({ commit }) {
    commit('set')
  },
  async validation(ctx, { id, param }) {
    // const signature = HmacSHA256(uid(), this.$config.XSJSX).toString()
    this.$axios.setHeader('X-Signature', uid(32))
    return await this.$axios
      .$post(`/tiers/${id}/validation`, param)
      .catch((err) => {
        console.log('err:', err)
        ctx.commit('set', { validateLoading: false })
      })
  },
  async redemption(ctx, { id, param }) {
    console.log('validate par:', param)
    if (typeof id === 'undefined') return
    this.$axios.setHeader('X-Signature', uid(32))
    return await this.$axios
      .$post(`/tiers/${id}/redemption`, param)
      .catch((err) => {
        console.log('err:', err)
        ctx.commit('set', { validateLoading: false })
      })
  },
  async getList(ctx) {
    // const signature = HmacSHA256(uid(), this.$config.XSJSX).toString()
    // this.$axios.setHeader('X-Signature', signature)
    this.$axios.setHeader('X-Signature', uid(32))

    return await this.$axios
      .$get(`/promotions`)
      .then((result) => {
        console.log('result:', result)
        if (result?.vouchers) {
          ctx.commit('set', { vouchers: result.vouchers, total: result.total })
        }
      })
      .catch((err) => {
        console.log('err:', err)
      })
  },
  async mapPromotion(ctx, { param, tierId }) {
    const defMessage = 'redemption does not match validation rules'
    try {
      const req = await ctx.dispatch('validation', {
        id: tierId,
        param,
      })

      const errMessage = req.data?.error?.message || req.data?.reason || ''

      if (req?.data && req.data.campaign && req.data.valid) {
        const vpromotion = {
          id: req.data.id,
          valid: req.data.valid,
          name: req.data.name,
          banner: req.data.banner,
          tracking_id: req.data.tracking_id,
          order: req.data.order,
          discount: req.data.discount,
          discount_amount: req.data.discount_amount || 0,
          applied_discount_amount: req.data.applied_discount_amount || 0,
          metadata: req.data.metadata,
        }
        ctx.commit('set', {
          validate: vpromotion,
          validateLoading: false,
        })
        const order = vpromotion.order
        const totalDisc = order.total_applied_discount_amount / 100 || 0
        const totalAmount = order.total_amount / 100 || 0

        if (vpromotion.discount.effect === 'APPLY_TO_ORDER') {
          ctx.commit('setTotalDiscount', Math.round(parseInt(totalDisc)))
          ctx.commit('cart/setTotalAmount', Math.round(parseInt(totalAmount)), {
            root: true,
          })
        }
      } else {
        if (errMessage && !errMessage.includes(defMessage))
          if (this.$config.APP_DEBUG === 'on') {
            this.$toast.info(`&#9432; ${errMessage}`, {
              duration: 3000,
            })
          }
        ctx.commit('resetValidate')
      }
    } catch (err) {
      if (err?.response?.code === 429) {
        ctx.commit('resetValidate')
      }
      console.error('err validatePromotion: ', err)
    }
  },
  async validatePromotion(ctx, { tierId, sourceId, totalOrder }) {
    await ctx.commit('set', {
      validateLoading: true,
    })
    if (tierId === '') {
      console.error('Promotion tier ID not found')
      return
    }
    const param = {
      customer: {
        source_id: sourceId,
      },
      order: {
        amount: totalOrder * 100,
      },
    }
    // const defMessage = 'redemption does not match validation rules'
    try {
      const req = await ctx.dispatch('validation', {
        id: tierId,
        param,
      })

      // console.log('validatePromotion req:', req)

      // const errMessage = req.data?.error?.message || req.data?.reason || ''

      if (req?.campaign && req?.valid) {
        const vpromotion = {
          id: req.id,
          valid: req.valid,
          name: req.name,
          banner: req.banner,
          tracking_id: req.tracking_id,
          order: req.order,
          discount: req.discount,
          discount_amount: req.discount_amount || 0,
          applied_discount_amount: req.applied_discount_amount || 0,
          metadata: req.metadata,
        }
        ctx.commit('set', {
          validate: vpromotion,
        })

        const order = vpromotion.order
        const totalDisc = order.total_applied_discount_amount / 100 || 0
        const totalAmount = order.total_amount / 100 || 0

        if (vpromotion.discount.effect === 'APPLY_TO_ORDER') {
          ctx.commit('setTotalDiscount', Math.round(parseInt(totalDisc)))

          ctx.commit('cart/setTotalAmount', Math.round(parseInt(totalAmount)), {
            root: true,
          })
        }
      } else {
        // if (errMessage && !errMessage.includes(defMessage))
        //   if (this.$config.APP_DEBUG === 'on') {
        //     this.$toast.info(`&#9432; ${errMessage}`, {
        //       duration: 3000,
        //     })
        //   }
        ctx.commit('resetValidate')
      }
    } catch (err) {
      console.error('err validatePromotion: ', err)
    }

    await ctx.commit(
      'set',
      {
        confirmPayLoading: false,
      },
      { root: true }
    )
  },
  async resetPromoValidate({ commit, dispatch }) {
    await commit('resetValidate')
    await dispatch('cart/recalcTotalDiscount', '', { root: true })
    await dispatch('cart/calcTotalAmount', '', { root: true })
  },
}
