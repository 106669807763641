import Tracker from '@openreplay/tracker'
import { uid } from 'uid'

function defaultUserId() {
  return uid()
}

export default ({ app, isDev }, inject) => {
  inject('openReplayTrack', async () => {
    console.log('openReplayTrack isDev:', isDev)
    const store = await app.store
    await store.dispatch('config/getConfig', 'openReplayOn')
    const openReplayOn = await store.state.config.openReplayOn
    if (openReplayOn) {
      const loggedIn = store.$auth.loggedIn
      const acc = store.state.shopper.account
      const userId = loggedIn ? acc.phone : defaultUserId()
      const email = loggedIn ? acc.email : ''
      const name = loggedIn ? acc.first_name + ' ' + acc.last_name : ''

      if (app.$config.OPEN_REPLAY_KEY !== '') {
        const trackerConfig = {
          projectKey: app.$config.OPEN_REPLAY_KEY,
          ingestPoint: 'https://openreplay.useflik.com/ingest',
          __DISABLE_SECURE_MODE: isDev,
          obscureTextNumbers: false,
          obscureTextEmails: false,
          capturePerformance: true,
          onStart: ({ sessionID }) =>
            console.log('OpenReplay tracker started with session: ', sessionID),
          network: {
            sessionTokenHeader: true,
            ignoreHeaders: false,
            capturePayload: true,
          },
        }

        console.log('openReplayTrack trackerConfig:', trackerConfig)
        const tracker = new Tracker(trackerConfig)

        console.log('Starting tracker config...', userId)

        tracker.setUserID(userId)

        tracker.start({
          userID: userId,
          metadata: {
            email: email,
            fullname: name,
          },
        })
      } else {
        console.log('openReplay empty key')
      }
    }
  })
}
