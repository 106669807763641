import * as configcat from 'configcat-js'

const initState = () => {
  return {
    isRewardEnable: false,
    promoPostPurchaseOffer: true,
    openReplayOn: false,
  }
}

export const state = () => initState()

export const mutations = {
  set(state, params) {
    const keys = Object.keys(params)
    keys.forEach((key) => (state[key] = params[key]))
  },
  reset(state) {
    Object.assign(state, initState())
  },
}

export const actions = {
  async getConfig({ commit }, featureKey) {
    if (this.$config.CONFIG_KEY.length > 0)
      try {
        const option = {
          cacheTimeToLiveSeconds: 600,
        }

        if (this.$config.APP_DEBUG === 'on') {
          const logger = configcat.createConsoleLogger(configcat.LogLevel.Info)
          // Set the log level to INFO to track how your feature flags were evaluated.
          // When moving to production, you can remove this line to avoid too detailed logging.
          Object.assign(option, { logger })
        }

        const configCatClient = configcat.getClient(
          this.$config.CONFIG_KEY,
          // configcat.PollingMode.LazyLoad,
          configcat.PollingMode.AutoPoll,
          option
        )

        const value = await configCatClient.getValueAsync(featureKey, false)

        if (value) {
          console.warn(`${featureKey} value`, value)
          const obj = {
            [featureKey]: value,
          }
          commit('set', obj)
        }
      } catch (err) {
        console.error('getConfigCat', err)
      }
  },
}
