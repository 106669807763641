export default async function ({ store, redirect, $auth }) {
  // check if formattedPhone number submitted
  const formattedPhone = await store.state.otp.formattedPhone
  const expiresAt = await store.state.otp.expiresAt
  if (formattedPhone === '') {
    return redirect('/')
  }
  if (expiresAt === 0) {
    return redirect('/')
  }
  if ($auth.loggedIn) {
    return redirect('/profile')
  }

  // End Redirect
}
