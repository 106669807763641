import { render, staticRenderFns } from "./change.vue?vue&type=template&id=f62cd14e&"
import script from "./change.vue?vue&type=script&lang=js&"
export * from "./change.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HorizontalMenu: require('/runner/_work/checkout-payment/checkout-payment/components/HorizontalMenu.vue').default})
