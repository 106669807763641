export default async function ({ store, redirect, $auth }) {
  const loggedIn = await $auth.loggedIn
  if (loggedIn) {
    await store.dispatch('shopper/get')
    const shopper = await store.state.shopper
    const hasAddress = await store.state.shopper.hasAddress

    console.log('%c-> middleware.cart shopper ', 'color:#FB923C;', shopper)
    if (!hasAddress) {
      return redirect('/address/add')
    }
  }
}
