import axios from 'axios'
import HmacSHA256 from 'crypto-js/hmac-sha256'
import { uid } from 'uid'

const mergeArr = (obj1, obj2) => {
  const a = obj1.slice()
  const b = obj2.slice()
  let newArr = [].concat(a, b)
  const len = b.length
  for (let i = 0; i < len; i++) {
    const item = a.find(
      (x) =>
        x.merchant_id === b[i].merchant_id && x.product_id === b[i].product_id
    )

    if (item) {
      newArr = newArr.filter(
        (x) =>
          x.merchant_id !== b[i].merchant_id && x.product_id !== b[i].product_id
      )
      const nitem = { ...item }
      const newqty = parseInt(nitem.qty + b[i].qty)
      Object.assign(nitem, { qty: newqty })
      // push new item to merged array
      newArr.push(nitem)
    }
  }
  return newArr
}

const productMap = (prod, is_checkout_platform = false) => {
  let options = []
  if (prod?.dynamic_attributes)
    options = Object.values(prod?.dynamic_attributes)
  const use_variant = options.length > 0
  return {
    merchant_id: prod.merchant_id,
    merchant_name: prod.merchant_name,
    merchant_logo: prod.merchant_logo,
    merchant_item_id: prod.merchant_item_id,
    warehouse_id: prod.wh.id,
    warehouse_location: prod.wh.city,
    warehouse_lat: prod.wh.lat,
    warehouse_lng: prod.wh.lng,
    warehouse_postal_code: prod.wh.postal_code,
    product_id: prod.item_id,
    title: prod.name,
    qty: prod.qty,
    length: prod.volume.length,
    width: prod.volume.width,
    height: prod.volume.height,
    weight: prod.weight,
    unit_price: prod.unit_price || 0,
    sell_price: prod.is_discount ? prod.discount_price : prod.unit_price || 0,
    disc_price: prod.discount_price || 0,
    is_discount: prod.is_discount,
    subtotal_amount: prod?.subtotal_amount || 0,
    total_amount: prod?.total_amount || 0,
    use_variant,
    stock_available: prod.stock_available,
    options,
    image: prod.image_url,
    detail_url: prod.detail_url,
    merchant_slug: prod.merchant_slug,
    product_slug: prod.product_slug,
    product_fresh: prod?.product_fresh || false,
    is_checkout_platform,
    item_level_discounts: prod.item_level_discounts || [],
    info: prod.info || null,
  }
}

const initState = () => {
  return {
    reqFake: false,
    totalOrder: 0,
    totalDiscount: 0,
    totalOrderDiscount: 0,
    originTotalAmount: 0,
    totalDiscountAmount: 0,
    originTotalDiscountAmount: 0,
    totalAmount: 0,
    subtotalBeforeDiscount: 0,
    orderDiscounts: [],
    discountLines: [],
    removableDiscounts: [],
    subtotal: 0,
    total: 0,
    cartUrl: '',
    cartSurl: '',
    cartId: '',
    cartIdNotes: '',
    cartFrom: '',
    cartRedirUrl: '',
    cartRedirBack: '',
    cartExpired: false,
    storeAppId: '',
    storePixelId: '',
    totalQty: 0,
    ichecked: [],
    wchecked: [],
    checkedIds: [],
    totalChecked: 0,
    checkedItems: [],
    unCheckedIds: [],
    unCheckedItems: [],
    items: [],
    notes: [],
    raw: [],
    ownItems: [],
    sharedItems: [],
    isLoading: false,
    fromShopper: false,
    hasPpo: false,
    voucherFlikActive: false,
    areaRestrict: false,
  }
}

export const state = () => initState()

export const mutations = {
  set(state, params) {
    const keys = Object.keys(params)
    keys.forEach((key) => (state[key] = params[key]))
  },
  reset(state) {
    Object.assign(state, initState())
  },
  resetRaw(state) {
    state.cartExpired = false
    state.checkedIds = []
    state.checkedItems = []
    state.fromShopper = false
    state.ichecked = []
    state.isLoading = false
    state.items = []
    state.ownItems = []
    state.raw = []
    state.sharedItems = []
    state.total = 0
    state.totalChecked = 0
    state.totalDiscount = 0
    // state.totalOrder = 0
    state.totalQty = 0
    state.unCheckedIds = []
    state.unCheckedItems = []
    state.wchecked = []
    // state.reqFake = false
    state.originTotalAmount = 0
    state.totalDiscountAmount = 0
    state.originTotalDiscountAmount = 0
    state.totalAmount = 0
    state.subtotalBeforeDiscount = 0
    state.orderDiscounts = []
    state.discountLines = []
    state.removableDiscounts = []
  },
  setLoading(state, par) {
    state.isLoading = par
  },
  resetTotalDiscount(state) {
    state.totalDiscount = 0
  },
  setTotalDiscount(state, par) {
    state.totalDiscount = par
    if (par > state.totalDiscount) {
      state.totalOrder = 0
    } else {
      state.totalOrder = state.totalDiscount - par
    }
  },
  addTotalOrder(state, par) {
    state.totalOrder = state.total + par
    if (state.totalDiscount > state.totalOrder) {
      state.totalOrder = 0
    } else {
      state.totalOrder = state.total + par - state.totalDiscount
    }
  },
  setTotalOrder(state, par) {
    state.totalOrder = par
  },
  setTotalOrderAmount(state, par) {
    state.totalOrder = par
    state.totalChecked = par
    state.total = par
    state.totalAmount = par
  },
  setCartApi(state, par) {
    state.api = par
  },
  add(state, param) {
    state.total += param.sell_price * param.qty
    state.totalAmount += param.sell_price * param.qty
    state.subtotalBeforeDiscount += param.sell_price * param.qty
    state.raw.push(param)
  },
  update(state, param) {
    const inRaw = state.raw.find((x) => x.product_id === param.product_id)
    // adjust total cart
    state.total -= inRaw.sell_price * inRaw.qty
    state.total += param.sell_price * param.qty
    // replace raw cart
    const newRawPar = state.raw.map((el) => {
      if (el.product_id === param.product_id) {
        return param
      }
      return el
    })
    state.raw = newRawPar
  },
  groupby(state) {
    // cart items grouped by warehouse_id
    const group = state.raw.reduce((r, a) => {
      // a.subtotal = a.sell_price * a.qty || 0
      r[a.warehouse_id] = [...(r[a.warehouse_id] || []), a]
      return r
    }, {})

    state.items = group
  },
  add_select(state, item) {
    // find same product id
    // const sameMerchant = state.items.find((x) => x.merchant_id === item.merchant_id)
    state.select_items.push(item)
  },
  bulk(state, params) {
    state.raw = params
  },
  remove(state, item) {
    const itemIdx = state.raw.findIndex((x) => x.product_id === item.product_id)
    state.total -= item.sell_price * item.qty
    state.raw.splice(itemIdx, 1)
  },
  checkedItem(state) {
    const items = state.raw.map((b) => {
      return b.warehouse_id + '_' + b.product_id
    })
    const uniqueItems = [...new Set(items)]
    state.ichecked = uniqueItems
  },
  calcTotalChecked(state) {
    const itemIds = state.ichecked.map((x) => {
      return x.split('_')[1]
    })
    state.checkedIds = itemIds

    const checkedItems = state.raw.filter((x) => itemIds.includes(x.product_id))
    state.checkedItems = checkedItems
    // calc total checked items
    let total = 0
    checkedItems.forEach((ci) => {
      total += ci.sell_price * ci.qty
      return total
    })
    let totalQty = 0
    checkedItems.forEach((ci) => {
      totalQty += ci.qty
      return totalQty
    })
    state.totalQty = totalQty
    state.totalChecked = total
    state.totalOrder = total
  },
  unCheckedIds(state, warehouse = '') {
    if (warehouse !== '') {
      const unCheckedItems = state.raw.filter(
        (x) => x.warehouse_id === warehouse
      )
      state.unCheckedItems = unCheckedItems

      const unCheckedIds = unCheckedItems.map((b) => {
        return b.product_id
      })
      state.unCheckedIds = unCheckedIds
    }

    if (state.checkedItems.length === state.raw.length) {
      state.unCheckedItems = []
      state.unCheckedIds = []
    }
  },
  checkedWarehouse(state) {
    const items = state.raw.map((b) => {
      return b.warehouse_id
    })
    const uniqueItems = [...new Set(items)]
    state.wchecked = uniqueItems
  },
  checkWarehouse(state, warehouse) {
    const warehouses = state.wchecked.filter((w) => w !== warehouse)
    state.wchecked = warehouses
  },
  checkItemsWarehouse(state, warehouse) {
    const witems = state.ichecked.filter((i) => i.split('_')[0] !== warehouse)
    const uniqueItems = [...new Set(witems)]
    state.ichecked = uniqueItems
  },
  addWarehouse(state, warehouse) {
    state.wchecked.push(warehouse)
  },
  addItemsWarehouse(state, warehouse) {
    const items = state.raw
      .filter((b) => b.warehouse_id === warehouse)
      .map((b) => {
        return b.warehouse_id + '_' + b.product_id
      })

    const concatItems = state.ichecked.concat(items)
    state.ichecked = [...new Set(concatItems)]
  },
  setCartId(state, param) {
    state.cartId = param
    state.cartIdNotes = param
  },
  setCartFrom(state, param) {
    state.cartFrom = param
  },
  setCartRedirBack(state, param) {
    state.cartRedirBack = param
  },
  setFromShopper(state, param) {
    state.fromShopper = param
  },
  resetCartPlatform(state) {
    state.cartInfo = ''
    state.cartFrom = ''
    state.cartRedirUrl = ''
    state.cartRedirBack = ''
    state.storeAppId = ''
    state.hasPpo = false
  },
  setNotes(state, param) {
    const note = state.notes.find(
      (item) => item.warehouse_id === param.warehouse_id
    )
    if (note) {
      note.note = param.note
    } else {
      state.notes.push(param)
    }
  },
  checkedNoteMutation(state) {
    const items = state.raw.map((b) => {
      return b.warehouse_id
    })
    const uniqueItems = [...new Set(items)]
    const filter = state.notes.filter((note) =>
      uniqueItems.includes(note.warehouse_id)
    )
    state.notes = filter
  },
  setOrderDiscounts(state, param) {
    state.orderDiscounts = param
    let totalDisc = 0
    for (const disc of param) {
      totalDisc = disc.discount_amount
    }
    state.totalOrderDiscount = totalDisc
  },
  setDiscountLines(state, param) {
    state.discountLines = param
  },
  setRemoveableDiscounts(state, param) {
    state.removableDiscounts = param
  },
  setOriginTotalDiscountAmount(state, par) {
    state.originTotalDiscountAmount = par
  },
  setTotalDiscountAmount(state, par) {
    state.totalDiscountAmount += par
  },
  resetTotalDiscountAmount(state) {
    state.totalDiscountAmount = state.originTotalDiscountAmount
  },
  addTotalAmount(state, par) {
    console.log('addTotalAmount par:', par)
    state.totalAmount += par
  },
  setTotalAmount(state, par) {
    state.totalAmount = par
  },
  setOriginTotalAmount(state, par) {
    state.originTotalAmount = par
  },
  resetTotalAmount(state) {
    state.totalAmount = state.originTotalAmount
  },
  subtractTotalAmount(state, par) {
    state.totalAmount -= par
  },
  setSubtotal(state, param) {
    state.subtotal_amount = param
  },
  setSubtotalBeforeDiscount(state, param) {
    state.subtotalBeforeDiscount = param
  },
  setReqFake(state, param) {
    console.log('setReqFake:', param)
    state.reqFake = param
  },
  selectPickup(state, param) {
    state.selectedPickup = param
  },
}

export const actions = {
  async recalcTotalDiscount({ commit, rootState }) {
    await commit('resetTotalDiscount')
    let par = 0
    if (rootState.shipping.totalCost > 0) {
      par = rootState.shipping.totalCost
    }
    await commit('addTotalOrder', par)
  },
  async calcTotalAmount({ commit, rootState, state }) {
    await commit('resetTotalAmount')
    await commit('resetTotalDiscountAmount')
    const totalShipping = await rootState.shipping.totalCost
    if (totalShipping > 0) {
      await commit('addTotalAmount', totalShipping)
    }

    console.log(
      '%c-> calcTotalAmount - hasPpo ',
      'color:#FB923C;',
      state.hasPpo
    )

    if (state.hasPpo) {
      const price = await rootState.sproduct.price
      const prodPrice = (await price.discount)
        ? price.discount_price
        : price.sell_price
      await commit('addTotalAmount', prodPrice)
    }

    const totalReward = await rootState.reward.totalSelected

    console.log(
      '%c-> calcTotalAmount : totalReward ',
      'color:#FB923C;',
      totalReward
    )

    console.log(
      '%c-> calcTotalAmount : totalAmount ',
      'color:#FB923C;',
      state.totalAmount
    )

    if (totalReward > 0 && totalReward < state.totalAmount) {
      await commit('subtractTotalAmount', totalReward)
    } else if (totalReward >= state.totalAmount) {
      console.log(
        '%c-> calcTotalAmount : totalReward > totalAmount ',
        'color:#FB923C;',
        totalReward > state.totalAmount
      )
      await commit('subtractTotalAmount', state.totalAmount)
    }

    console.log(
      '%c-> calcTotalAmount : totalDiscountAmount ',
      'color:#FB923C;',
      totalShipping
    )
  },
  async removeItem(ctx, par) {
    console.group('cart/removeItem:', par)
    await ctx.commit('setLoading', true)
    await ctx.commit(
      'set',
      {
        confirmPayLoading: true,
      },
      { root: true }
    )
    // remove any voucher selected
    // remove item from API
    if (this.$auth.loggedIn) {
      const items = [par.product_id]
      await ctx
        .dispatch('delete', { data: { items: items } })
        .then((res) => {
          if (res?.data?.items?.length > 0) {
            ctx.dispatch('replaceItems', { items: res.data.items })
            ctx.commit('groupby')
            ctx.commit('checkedItem')
            ctx.commit('checkedWarehouse')
            ctx.commit('calcTotalChecked')
            if (
              res.data.discount_amount > 0 &&
              this.$config.VOUCHERIFY_CAMP === ''
            ) {
              ctx.commit('setTotalDiscount', res.data.discount_amount)
              ctx.commit('setTotalDiscountAmount', res.data.discount_amount)
            }
          } else {
            ctx.commit('setTotalDiscount', 0)
            ctx.commit('setTotalDiscountAmount', 0)
            ctx.commit('remove', par)
            ctx.commit('groupby')
            ctx.commit('checkedItem')
            ctx.commit('checkedWarehouse')
            ctx.commit('calcTotalChecked')
          }

          // re-validate flik voucher after remove item
          ctx.commit('set', {
            voucherFlikActive: res.data.metadata?.flik_voucher || false,
          })
          // ctx.dispatch('shipping/removeCost',
          //   par.warehouse_id, { root: true }
          // )
        })
        .catch((err) => {
          console.error('DEL error', err)
          // this.$toast.error('Delete item faield, cart not found', {
          //   duration: 2000,
          // })
        })
    } else {
      ctx.commit('remove', par)
      ctx.commit('groupby')
      ctx.commit('checkedItem')
      ctx.commit('checkedWarehouse')
      ctx.commit('calcTotalChecked')
    }
    await ctx.dispatch('checkNotes')
    await ctx.commit('setLoading', false)
    await ctx.commit(
      'set',
      {
        confirmPayLoading: false,
      },
      { root: true }
    )
    console.groupEnd()
  },
  removeLimitedOffer(ctx, par) {
    ctx.commit('remove', par)
    ctx.commit('groupby')
    ctx.commit('checkedItem')
    ctx.commit('checkedWarehouse')
    ctx.commit('calcTotalChecked')
  },
  delete(ctx, items) {
    const CARTSVC = this.$config.CARTSVC
    return this.$axios
      .$delete(`${CARTSVC}/v1/cart/items`, items)
      .catch((err) => {
        console.error('action cart/delete:', err)
        // TODO temporary fix issue not redirect to payment/qr
        return true
      })
  },
  // checkItem(ctx, item) {
  //   ctx.commit('uncheck', item)
  //   ctx.commit('calcTotalChecked')
  // },
  checkWarehouseItems(ctx, warehouse) {
    const fwarehouse = ctx.state.wchecked.find((i) => i === warehouse)
    if (fwarehouse) {
      ctx.commit('checkWarehouse', warehouse)
      ctx.commit('checkItemsWarehouse', warehouse)
      ctx.commit('unCheckedIds', warehouse)
    } else {
      ctx.commit('addWarehouse', warehouse)
      ctx.commit('addItemsWarehouse', warehouse)
      ctx.commit('unCheckedIds', warehouse)
    }
    ctx.commit('calcTotalChecked')
  },
  updateQty(ctx, par) {
    ctx.commit('update', par)
    ctx.commit('groupby')
    // should check if qty update is checked item ?
    if (ctx.state.checkedIds.includes(par.product_id)) {
      ctx.commit('calcTotalChecked')
    }
    // ctx.commit('checkedWarehouse')
  },
  async add(ctx, par) {
    // find same product
    const sameP = await ctx.state.raw.find(
      (x) => x.product_id === par.product_id
    )
    if (sameP) {
      const qty = par.qty + sameP.qty || 0
      const updatedProduct = { ...par, qty }
      ctx.commit('update', updatedProduct)
      ctx.commit('groupby')
    } else {
      ctx.commit('add', par)
      // ctx.commit('checked', par)
      ctx.commit('groupby')
    }
    await ctx.commit('checkedItem')
    await ctx.commit('checkedWarehouse')
    await ctx.commit('calcTotalChecked')
  },
  async empty(ctx) {
    if (this.$auth.loggedIn) {
      const items = ctx.state.checkedIds
      await ctx.dispatch('delete', { data: { items: items } })
    }
    await ctx.commit('resetRaw')
  },
  async addToLocal(ctx, { prod, qty, withSKU = false }) {
    console.log('%c-> addToLocal - prod ', 'color:#FB923C;', prod)
    if (!prod.stock_available) return false
    const opts = prod.use_variant ? Object.values(prod.variant) : []
    const pid = prod.id
    // const merchantId = prod.merchant.id.substring(0, 8)
    const disc_price = prod.price.discount_price || 0
    const sell_price = prod.price.sell_price || 0
    let subtotal_amount = 0
    let total_amount = 0
    if (ctx.state.cartFrom !== '') {
      subtotal_amount = prod?.subtotal_amount
      total_amount = prod?.total_amount
    } else {
      subtotal_amount = prod.price.discount ? disc_price : sell_price
      total_amount = prod.price.discount ? disc_price * qty : sell_price * qty
    }

    const sourceCond =
      ctx.state.cartId.length > 0 && !prod.source === 'flik.co.id'

    const product = {
      merchant_id: prod.merchant.id,
      merchant_name: prod.merchant.name,
      merchant_logo: prod.merchant.logo_url,
      warehouse_id: prod.default_warehouse.id,
      warehouse_location: prod.default_warehouse.city,
      warehouse_lat: 0.0,
      warehouse_lng: 0.0,
      warehouse_postal_code: prod.default_warehouse.postal_code,
      product_id: pid,
      title: prod.name,
      qty,
      length: prod.volume.length,
      width: prod.volume.width,
      height: prod.volume.height,
      weight: prod.weight,
      unit_price: prod.price.sell_price || 0,
      sell_price: prod.price.discount ? disc_price : sell_price,
      disc_price: disc_price || 0,
      subtotal_amount,
      total_amount,
      stock_available: prod.stock_available,
      is_discount: prod.price.discount,
      use_variant: prod.use_variant,
      options: opts,
      image: prod.image_url,
      detail_url: prod.detail_url,
      merchant_slug: prod.merchant_slug,
      product_slug: prod.product_slug,
      is_checkout_platform: sourceCond,
    }
    if (withSKU) {
      product.merchant_item_id = prod.sku_variant
      product.is_checkout_platform = true
    }
    await ctx.dispatch('add', product)
    // no need to recalculate shipping here anymore
    // if (this.$auth.loggedIn) {
    //   await ctx.dispatch('shipping/groupRecheckRate', {}, { root: true })
    // }
    console.groupEnd()
  },
  async upsert(ctx, pars) {
    await ctx.commit('setLoading', true)
    await ctx.commit('setReqFake', false)
    await ctx.commit(
      'set',
      {
        confirmPayLoading: true,
      },
      { root: true }
    )
    const CARTSVC = this.$config.CARTSVC
    await this.$axios
      .$post(`${CARTSVC}/v1/cart/items`, pars)
      .then(async (res) => {
        // if (res?.data) {
        //   ctx.commit('setCartApi', res.data)
        // }
        if (res?.data?.items?.length > 0) {
          await ctx.dispatch('replaceItems', { items: res.data.items })
        }

        if (res.data?.pickup_warehouses?.length) {
          await ctx.commit(
            'warehouse/setWarehouses',
            res.data.pickup_warehouses,
            {
              root: true,
            }
          )
          const shipType = await ctx.rootState.shipType
          if (shipType === 'pickup') {
            await ctx.commit('shipping/setPickupCost', null, { root: true })
          }
        } else {
          await ctx.commit('warehouse/reset', null, {
            root: true,
          })
          await ctx.commit(
            'set',
            { shipType: 'delivery' },
            {
              root: true,
            }
          )
        }

        // new calculation, removeable discount, & auto discount
        await ctx.dispatch('setCalculation', res.data)
        // new calculation, removeable discount, & auto discount

        // if (
        //   res.data.discount_amount > 0 &&
        //   this.$config.VOUCHERIFY_CAMP === ''
        // ) {
        //   ctx.commit('setTotalDiscount', res.data.discount_amount)
        //   ctx.commit('setTotalDiscountAmount', res.data.discount_amount)
        // }
        if (ctx.state.cartId.length > 0 && res?.data?.items.length === 0) {
          ctx.commit('resetCartPlatform')
        }
        if (res?.data?.items.length === 0) {
          ctx.commit('resetRaw')
        }
        // re-validate voucher after upsert
        ctx.commit('set', {
          notes: res.data.notes,
          cartIdNotes: res.data.cart_id,
          cartId: res.data.cart_id,
          voucherFlikActive: res.data.metadata?.flik_voucher || false,
        })
        // this.$toast.info('Shopping cart updated', { duration: 7000 })
      })
      .catch((err) => {
        console.error('Upsert error', err)
      })
    await ctx.commit('setLoading', false)
    await ctx.commit(
      'set',
      {
        confirmPayLoading: false,
      },
      { root: true }
    )
  },
  async get(ctx) {
    await ctx.commit('setLoading', true)
    await ctx.commit('setReqFake', false)
    await ctx.commit(
      'set',
      {
        confirmPayLoading: true,
      },
      { root: true }
    )
    const CARTSVC = this.$config.CARTSVC
    await this.$axios
      .$get(`${CARTSVC}/v1/cart/shopper`)
      .then(async (res) => {
        if (res.data?.items?.length > 0) {
          await ctx.commit('set', {
            cartExpired: false,
            cartUrl: res.data.cart_url,
            cartFrom: '',
            cartId: res.data.cart_id,
            cartIdNotes: res.data.cart_id,
            cartInfo: res.data?.info || null,
            ownItems: res.data.items,
            notes: res.data.notes,
            voucherFlikActive: res.data.metadata?.flik_voucher || false,
            areaRestrict: res.data?.is_area_restricted || false,
          })
          await ctx.dispatch('replaceItems', { items: res.data.items })

          // rem prev shipDiscount if merchant not in cart
          console.log('rem prev shipping discount if not in cart:')
          const wchecked = await ctx.state.wchecked
          for (const sd of ctx.rootState.shipping.shipDiscount) {
            const inWChecked = wchecked.includes(sd.merchant_id)
            if (!inWChecked) {
              await ctx.commit(
                'shipping/removeMerchantShipDiscount',
                sd.merchant_id,
                { root: true }
              )
            }
          }
          // rem prev shipDiscount if merchant not in cart

          // pickup warehouse
          if (res.data?.pickup_warehouses?.length) {
            await ctx.commit(
              'warehouse/setWarehouses',
              res.data.pickup_warehouses,
              {
                root: true,
              }
            )
            const shipType = await ctx.rootState.shipType
            if (shipType === 'pickup') {
              await ctx.commit('shipping/setPickupCost', null, { root: true })
            }
          } else {
            await ctx.commit('warehouse/reset', null, {
              root: true,
            })
            await ctx.commit(
              'set',
              { shipType: 'delivery' },
              {
                root: true,
              }
            )
          }
          // pickup warehouse

          // new calculation, removeable discount, & auto discount
          await ctx.dispatch('setCalculation', res.data)
          // new calculation, removeable discount, & auto discount

          // if (
          //   res.data.discount_amount > 0 &&
          //   this.$config.VOUCHERIFY_CAMP === ''
          // ) {
          //   await ctx.commit('setTotalDiscount', res.data.discount_amount)
          // }
        }
        if (res?.data?.items.length === 0) {
          await ctx.commit('resetRaw')
        }
      })
      .catch((err) => {
        console.error('GET cart error', err)
        return true
      })
    await ctx.commit('setLoading', false)
    await ctx.commit(
      'set',
      {
        confirmPayLoading: false,
      },
      { root: true }
    )
  },
  async getById(ctx, id) {
    await ctx.commit('setLoading', true)
    await ctx.commit(
      'set',
      {
        confirmPayLoading: true,
      },
      { root: true }
    )
    const CARTSVC = this.$config.CARTSVC
    await this.$axios
      .$get(`${CARTSVC}/v1/cart?id=${id}`)
      .then(async (res) => {
        if (res?.data?.items?.length > 0) {
          await ctx.commit('set', {
            cartExpired: false,
            sharedItems: res.data.items,
            cartInfo: res.data?.info || null,
          })
          const arr1 =
            (await this.$auth.loggedIn) && ctx.state.ownItems.length > 0
              ? ctx.state.ownItems.map((oi) => productMap(oi))
              : ctx.state.raw

          const arr2 = res.data.items.map((i) => productMap(i))

          const items = mergeArr(arr1, arr2)

          await ctx.dispatch('replaceItems', { items, remap: false })
          res.data.items.forEach((p) => {
            if (p.qty === 1)
              console.warn(`QTY ${p.qty} items of  "${p.name}" added to cart`)
            else console.warn(`QTY ${p.qty} items of "${p.name}" added to cart`)
          })
          if (this.$auth.loggedIn) await ctx.dispatch('preUpSert')
          await this.$router.push('/cart')
        }

        if (res?.data?.items.length === 0) {
          ctx.commit('resetRaw')
        }
      })
      .catch((err) => {
        console.error('GET cart error', err)
      })
    await ctx.commit('setLoading', false)
    await ctx.commit(
      'set',
      {
        confirmPayLoading: false,
      },
      { root: true }
    )
  },
  async generateCartLink(ctx) {
    const checkedItems = ctx.state.checkedItems
    const cartId = ctx.state.cartId
    const cartFrom = ctx.state.cartFrom
    const cartUrl = ctx.state.cartUrl
    if (cartId) {
      console.warn('Only generate short links')
      let cartRedirBack = ctx.state.cartRedirBack || ''
      if (cartRedirBack) {
        const getHostname = new URL(cartRedirBack)
        cartRedirBack = `?back=${getHostname.origin}`
      }
      const url = cartUrl + cartRedirBack
      return await this.$axios
        .$post(`shortener/v1/shortener`, {
          urls: [url],
        })
        .then((res) => {
          if (res?.data) {
            ctx.commit('set', {
              cartSurl: res?.data[0]?.link || '',
            })
          }
        })
        .catch((err) => {
          console.error(`generateCartLink ${cartFrom} err:`, err)
        })
    } else {
      const buyCheckItems = []
      checkedItems.forEach((bc) => {
        const items = {
          item_id: bc.product_id,
          qty: bc.qty,
          note: bc.title + ' - ' + bc.options.join(','),
        }
        buyCheckItems.push(items)
      })
      const params = {
        items: buyCheckItems,
      }
      const CARTSVC = this.$config.CARTSVC

      return await this.$axios
        .$post(`${CARTSVC}/v1/cart/items/shared`, params)
        .then((res) => {
          if (res?.data) {
            ctx.commit('set', {
              cartSurl: res?.data?.short_url || '',
            })
          }
        })
        .catch((err) => {
          console.error('generateCartLink err:', err)
        })
    }
  },
  async getMerchant(ctx, pid) {
    // await ctx.commit('setLoading', true)
    const MERCHANTSVC = this.$config.MERCHANTSVC
    await this.$axios
      .$get(`${MERCHANTSVC}/v1/merchant/product-origin?product_id=${pid}`)
      .then((res) => {
        console.info('getMerchant response', res.data)
      })
      .catch((err) => {
        console.error('getMerchant error', err)
      })
    // await ctx.commit('setLoading', false)
  },
  async replaceItems(ctx, { items, remap = true }) {
    // empty local cart
    await ctx.commit('resetRaw')
    // assign items from API to local cart
    await items.forEach(async (prod) => {
      const isCheckoutPlatform = ctx.state.cartId.length > 0
      //  moved to func productMap
      const product = remap ? productMap(prod, isCheckoutPlatform) : prod
      await ctx.dispatch('add', product)
    })
    await ctx.commit('setLoading', false)
  },
  async preUpSert(ctx) {
    const buyCheckItems = []
    const checkedItems = ctx.state.checkedItems
    if (checkedItems.length > 0) {
      ctx.state.checkedItems.forEach((bc) => {
        const items = {
          item_id: bc.product_id,
          qty: bc.qty,
          is_checkout_platform: bc.is_checkout_platform,
          note: bc.options.join(','),
          name: bc.title,
        }
        buyCheckItems.push(items)
      })
      const params = {
        type: 'PRODUCT_INTERNAL',
        items: buyCheckItems,
      }
      await ctx.dispatch('upsert', params)
    }
  },
  async getCartId(ctx, { id, city }) {
    console.group('getCartId')
    await ctx.commit('setLoading', true)
    await ctx.commit('setReqFake', false)
    await ctx.commit(
      'set',
      {
        confirmPayLoading: true,
      },
      { root: true }
    )
    const CARTSVC = this.$config.CARTSVC

    const app_key = this.$config.APP_KEY
    const app_id = this.$config.APP_ID
    const request_id = uid()
    const signature = HmacSHA256(request_id + app_id, app_key).toString()
    const query = city ? `?city=${city}` : ''
    await this.$axios
      .$get(`${CARTSVC}/v1/platform/cart/${id}${query}`, {
        headers: {
          'X-App-ID': app_id,
          'X-Request-ID': request_id,
          'X-Signature': signature,
        },
      })
      .then(async (res) => {
        const resdata = res.data
        if (this.$validUUID(resdata.cart_id)) {
          // ctx.commit('setCartApi', resdata)
          await ctx.commit('set', {
            cartId: resdata.cart_id,
            cartFrom: resdata.type.toLowerCase(),
            cartExpired: false,
            cartRedirUrl: resdata?.option?.redirect_url,
            cartUrl: resdata.cart_url,
            notes: resdata.notes,
            storeAppId: resdata?.metadata?.store_app_id,
            storePixelId:
              resdata?.items[0]?.merchant_pixel_id || this.$config.FB_PIXEL_ID,
            hasPpo: resdata?.has_ppo || false,
            voucherFlikActive: resdata?.metadata?.flik_voucher || false,
            areaRestrict: resdata?.is_area_restricted || false,
            cartInfo: resdata?.info || null,
          })
        }
        if (resdata?.items?.length) {
          await ctx.dispatch('replaceItems', { items: resdata.items })

          if (resdata?.pickup_warehouses?.length) {
            await ctx.commit(
              'warehouse/setWarehouses',
              resdata.pickup_warehouses,
              {
                root: true,
              }
            )
            const shipType = await ctx.rootState.shipType
            if (shipType === 'pickup') {
              await ctx.commit('shipping/setPickupCost', null, { root: true })
            }
          } else {
            await ctx.commit('warehouse/reset', null, {
              root: true,
            })
            await ctx.commit(
              'set',
              { shipType: 'delivery' },
              {
                root: true,
              }
            )
          }

          // new calculation, removeable discount, & auto discount
          await ctx.dispatch('setCalculation', resdata)
          // new calculation, removeable discount, & auto discount
        } else {
          this.$toast.show('Cart has no items', { duration: 50000 })
        }
      })
      .catch((err) => {
        console.error('Cart platform err:', err)
        if (err?.response?.status === 404) {
          console.error(`Cart platform expired`, { duration: 50000 })
          ctx.commit('set', { cartExpired: true })
        } else if (this.$config.APP_DEBUG === 'on') {
          console.warn('getCartId error', err)
          this.$toast.error(`Cart error: ${err?.message}`, { duration: 50000 })
        }
      })
    await ctx.commit('setLoading', false)
    await ctx.commit(
      'set',
      {
        confirmPayLoading: false,
      },
      { root: true }
    )
    console.groupEnd()
  },
  async getCartIdFake(ctx, { id }) {
    console.group('getCartIdFake id:', id)
    await ctx.commit('setLoading', true)
    await ctx.commit('setReqFake', true)
    await ctx.commit(
      'set',
      {
        confirmPayLoading: true,
      },
      { root: true }
    )
    const url = `${window.location.protocol}://${window.location.hostname}:3003`
    const app_id = this.$config.APP_ID
    const request_id = uid()
    const headers = {
      headers: {
        'X-App-ID': app_id,
        'X-Request-ID': request_id,
      },
    }
    const req = axios.create({ url, headers })
    await req
      .get(`/fakeapi/cart-platform_${id}.json`)
      .then(async (res) => {
        const resdata = res.data.data
        if (this.$validUUID(resdata.cart_id)) {
          // ctx.commit('setCartApi', resdata)
          await ctx.commit('set', {
            cartId: resdata.cart_id,
            cartFrom: resdata.type.toLowerCase(),
            cartExpired: false,
            cartRedirUrl: resdata?.option?.redirect_url,
            cartUrl: resdata.cart_url,
            storeAppId: resdata?.metadata?.store_app_id,
            storePixelId:
              resdata?.items[0]?.merchant_pixel_id || this.$config.FB_PIXEL_ID,
            hasPpo: resdata?.has_ppo || false,
          })
        }
        if (resdata?.items?.length > 0) {
          await ctx.dispatch('replaceItems', { items: resdata.items })

          if (resdata?.pickup_warehouses?.length) {
            await ctx.commit(
              'warehouse/setWarehouses',
              resdata.pickup_warehouses,
              {
                root: true,
              }
            )
            const shipType = await ctx.rootState.shipType
            if (shipType === 'pickup') {
              await ctx.commit('shipping/setPickupCost', null, { root: true })
            }
          } else {
            await ctx.commit('warehouse/reset', null, {
              root: true,
            })
            await ctx.commit(
              'set',
              { shipType: 'delivery' },
              {
                root: true,
              }
            )
          }

          // new removeable discount, & auto discount
          await ctx.dispatch('setCalculation', resdata)
          // new removeable discount, & auto discount

          const query = this.app.router.history.current.query
          console.log('query:', query)
          const req = query.req || ''
          console.log('req:', req)

          if (req === 'fake') {
            const mid = resdata.items[0].merchant_id
            console.log('getRewardEstimationFake for:', mid)
            await ctx.dispatch('reward/getRewardEstimationFake', mid, {
              root: true,
            })
          }
        }
      })
      .catch((err) => {
        console.error('getCartIdFake cart error', err)
      })

    await ctx.commit('setLoading', false)
    await ctx.commit(
      'set',
      {
        confirmPayLoading: false,
      },
      { root: true }
    )
    console.groupEnd()
  },
  async removeNote(ctx, par) {
    await ctx.dispatch('checkNotes')
  },
  async setNote(ctx, param) {
    const payload = {
      warehouse_id: param.warehouse_id,
      note: param.note,
    }
    const id = ctx.state.cartId ? ctx.state.cartId : ctx.state.cartIdNotes
    ctx.commit('setNotes', payload)
    const CARTSVC = this.$config.CARTSVC
    await this.$axios.$put(`${CARTSVC}/v1/cart/notes?id=${id}`, {
      notes: ctx.state.notes,
    })
  },
  async checkNotes(ctx) {
    const id = ctx.state.cartId ? ctx.state.cartId : ctx.state.cartIdNotes
    ctx.commit('checkedNoteMutation')
    const CARTSVC = this.$config.CARTSVC
    await this.$axios.$put(`${CARTSVC}/v1/cart/notes?id=${id}`, {
      notes: ctx.state.notes,
    })
  },
  async setCalculation({ commit }, resdata) {
    if (resdata?.subtotal_before_discount) {
      await commit(
        'setSubtotalBeforeDiscount',
        resdata.subtotal_before_discount
      )
    }
    if (resdata?.subtotal_amount) {
      await commit('setSubtotal', resdata.subtotal_amount)
    }
    if (resdata?.total_amount > 0) {
      await commit('setTotalAmount', resdata.total_amount)
      await commit('setOriginTotalAmount', resdata.total_amount)
    } else {
      await commit('setTotalAmount', 0)
      await commit('setOriginTotalAmount', 0)
    }
    if (resdata?.discount_lines?.length) {
      await commit('setDiscountLines', resdata.discount_lines)
    } else {
      await commit('setDiscountLines', [])
    }
    if (resdata?.removable_discounts?.length) {
      await commit('setRemoveableDiscounts', resdata.removable_discounts)
    } else {
      await commit('setRemoveableDiscounts', [])
    }
    if (resdata?.total_discount_amount > 0) {
      await commit(
        'setOriginTotalDiscountAmount',
        resdata.total_discount_amount
      )
      await commit('setTotalDiscountAmount', resdata.total_discount_amount)
    } else {
      await commit('setOriginTotalDiscountAmount', 0)
      await commit('setTotalDiscountAmount', 0)
    }
  },
  async deleteCartPromo({ commit, dispatch, state }, voucher_code) {
    if (state.cartId === '') {
      console.error('Empty CartId')
      return
    }
    await commit('setLoading', true)
    await commit(
      'set',
      {
        confirmPayLoading: true,
      },
      { root: true }
    )
    const CARTSVC = this.$config.CARTSVC
    const app_key = this.$config.APP_KEY
    const app_id = this.$config.APP_ID
    const request_id = uid()
    const strBody = JSON.stringify(voucher_code)
    console.log('deleteCartPromo strBody:', strBody)
    const signature = HmacSHA256(
      strBody + request_id + app_id,
      app_key
    ).toString()

    await this.$axios
      .$delete(`${CARTSVC}/v1/platform/cart/promo/${state.cartId}`, {
        headers: {
          'X-App-ID': app_id,
          'X-Request-ID': request_id,
          'X-Signature': signature,
        },
        data: voucher_code,
      })
      .then(async (res) => {
        console.log('deleteCartPromo res', res.data)
        if (res.data?.items?.length > 0) {
          await dispatch('replaceItems', { items: res.data.items })
          await dispatch('setCalculation', res.data)
        }
      })
      .catch((err) => {
        console.error('deleteCartPromo error', err)
      })
    await commit('setLoading', false)
    await commit(
      'set',
      {
        confirmPayLoading: false,
      },
      { root: true }
    )
  },
  async postCartPromo({ commit, dispatch, state }, voucher_code) {
    if (state.cartId === '') {
      console.error('Empty CartId')
      return
    }
    await commit('setLoading', true)
    await commit(
      'set',
      {
        confirmPayLoading: true,
      },
      { root: true }
    )
    const CARTSVC = this.$config.CARTSVC
    const app_key = this.$config.APP_KEY
    const app_id = this.$config.APP_ID
    const request_id = uid()
    const strBody = JSON.stringify({ voucher_code })
    console.log('strBody:', strBody)
    const signature = HmacSHA256(
      strBody + request_id + app_id,
      app_key
    ).toString()

    await this.$axios
      .$post(
        `${CARTSVC}/v1/platform/cart/promo/${state.cartId}`,
        { voucher_code },
        {
          headers: {
            'X-App-ID': app_id,
            'X-Request-ID': request_id,
            'X-Signature': signature,
          },
        }
      )
      .then(async (res) => {
        if (res.data?.items?.length > 0) {
          await dispatch('replaceItems', { items: res.data.items })
          await dispatch('setCalculation', res.data)
        }
      })
      .catch((err) => {
        console.error('postCartPromo error', err)
      })
    await commit('setLoading', false)
    await commit(
      'set',
      {
        confirmPayLoading: false,
      },
      { root: true }
    )
  },
  // eslint-disable-next-line require-await
  async removePromo({ dispatch, commit }, code) {
    const param = { voucher_code: code }
    console.log('removePromo:', param)
    await commit(
      'set',
      {
        applyVoucherLoading: true,
      },
      { root: true }
    )
    await dispatch('deleteCartPromo', param).then((res) => {
      console.log('removeVoucher res:', res)
    })
    await dispatch('payment/checkCardInstallment', null, { root: true })
    await commit(
      'set',
      {
        applyVoucherLoading: false,
      },
      { root: true }
    )
  },
  // eslint-disable-next-line require-await
  async applyPromo({ dispatch, commit }, code) {
    await commit(
      'set',
      {
        applyVoucherLoading: true,
      },
      { root: true }
    )
    await dispatch('postCartPromo', code).then((res) => {
      console.log('postCartPromo res:', res)
    })

    await dispatch('payment/checkCardInstallment', null, { root: true })

    await commit(
      'set',
      {
        applyVoucherLoading: false,
      },
      { root: true }
    )
  },
}
