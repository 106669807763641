export default async function ({ store, redirect, $auth }) {
  const loggedIn = await $auth.loggedIn

  if (loggedIn) {
    await store.dispatch('shopper/get')
    const shopper = await store.state.shopper
    const hasAccount = await store.state.shopper.hasAccount
    console.log('%c-> middleware.cart shopper ', 'color:#FB923C;', shopper)
    if (!hasAccount) {
      return redirect('/profile')
    }
  }
}
