
import { mapActions, mapMutations, mapState } from 'vuex'
// import slugify from 'slugify'

export default {
  name: 'PayLayout',
  id: 'pay',
  head() {
    return {
      bodyAttrs: {
        class: this.$device.isDesktop ? 'desktop' : 'mobile',
      },
    }
  },
  computed: {
    ...mapState({
      shipType: (state) => state.shipType,
      confirmContinueTrx: (state) => state.confirmContinueTrx,
      msgContinueTrx: (state) => state.msgContinueTrx,
      rewardEstimation: (state) => state.reward.rewardEstimation || 0,
      shopperAcc: (state) => state.shopper.account,
      hasAddress: (state) => state.shopper.hasAddress,
      hasPayment: (state) => state.shopper.hasPayment,
      defAddress: (state) => state.shopper.defAddress,
      defPayment: (state) => state.shopper.defPayment,
      payOneTime: (state) => state.payment.payOneTime,
      savedPayments: (state) => state.shopper.saved_payments,
      confirmPayLoading: (state) => state.confirmPayLoading,
      showXenditModal: (state) => state.showXenditModal,
      applyVoucherLoading: (state) => state.applyVoucherLoading,
      checkedItems: (state) => state.cart.checkedItems,
      wchecked: (state) => state.cart.wchecked,
      storeAppId: (state) => state.cart.storeAppId,
      cartFrom: (state) => state.cart.cartFrom,
      cartId: (state) => state.cart.cartId,
      notes: (state) => state.cart.notes,
      hasPpo: (state) => state.cart.hasPpo,
      ppoData: (state) => state.ppo.product,
      scourier: (state) => state.shipping.scourier,
      totalShipping: (state) => state.shipping.totalCost,
      shipDisc: (state) => state.shipping.shipDiscount,
      cartExpired: (state) => state.cart.cartExpired,
      totalOrder: (state) => state.cart.totalAmount,
      lastTrxId: (state) => state.payment.lastTrxId,
      paymentType: (state) => state.payment.paymentType,
      loggedIn: (state) => state.auth.loggedIn,
      selVoucher: (state) => state.voucher.selVoucher,
      voucherRedeemables: (state) => state.voucher.validations.redeemables,
      voucherValidations: (state) => state.voucher.validations,
      removableDiscounts: (state) => state.cart.removableDiscounts,
      totalDiscountAmount: (state) => state.cart.totalDiscountAmount,
      voucherFlikActive: (state) => state.cart.voucherFlikActive,
      totalDiscountVoucher: (state) => state.voucher.totalDiscount,
      validPromotion: (state) => state.promotion.validate,
      voucherValidateLoading: (state) => state.voucher.validateLoading,
      totalVoucher: (state) => state.voucher.total,
      campaignPromotion: (state) => state.campaign.detail.promotion,
      listVoucher: (state) => state.voucher.list,
      isPromoPPOActive: (state) => state.config.promoPostPurchaseOffer,
      selectedVoucher: (state) => state.voucher.selectedVoucher,
      authUrl: (state) => state.payment.authUrl,
      cardPaymentLoading: (state) => state.cardPaymentLoading,
      areaRestrict: (state) => state.cart.areaRestrict,
    }),
    mLogoPlatform() {
      let str = 'flik'
      if (this.checkedItems.length > 0) {
        str = this.checkedItems[0].merchant_logo
      }
      return str
    },
    mNamePlatform() {
      let str = ''
      if (this.checkedItems.length > 0) {
        str = this.checkedItems[0].merchant_name
      }
      return str
    },
    cRoute() {
      return this.$route.name
    },
    totalPromotion() {
      let total = 0
      if (this.validPromotion.valid) {
        total = this.validPromotion.applied_discount_amount / 100
      }
      return total
    },
    // itemUnavailable() {
    //   return this.checkedItems.some((r) => !r.stock_available)
    // },
    confirmCondition() {
      const cond =
        this.wchecked.length > 0

      if (cond) return true
      else return false
    },
    payCond() {
      const listRoute = ['profile', 'payment', 'cart', 'cart-platform']
      const condRoute = listRoute.includes(this.cRoute)
      if (condRoute && this.loggedIn) return true
      else return false
    },
    labelBot() {
      let str = ''
      if (this.selVoucher.length > 0) {
        str = `Selected (${this.selVoucher.length})`
      }
      if (this.voucherValidations.valid && this.voucherRedeemables.length > 0) {
        str = `Applied (${this.selVoucher.length})`
      }
      return str
    },
    labelTop() {
      let str = `Available Voucher (${this.totalVoucher})`
      if (this.selVoucher.length > 0) {
        str = `Available Voucher (${this.totalVoucher})`
      }

      return str
    },
    condVoucherifyPromotion() {
      let cond = false
      console.log('VOUCHERIFY_CAMP', this.$config.VOUCHERIFY_CAMP)
      console.log('this.promoCode', this.promoCode)
      cond =
        this.$config.VOUCHERIFY_CAMP.replace(/\s+/g, '') !== '' &&
        this.voucherFlikActive
      console.log('condVoucherifyPromotion:', cond)
      return cond
    },
  },
  watch: {
    loggedIn() {
      if (!this.loggedIn) this.$router.push('/')
    },
  },
  mounted() {
    this.$openReplayTrack()

    console.warn(
      '%c-> isCustomDomain ',
      'color:#FF95ED;',
      this.$isCustomDomain()
    )
    // eslint-disable-next-line no-prototype-builtins
    console.log('pay window.Xendit', window.hasOwnProperty('Xendit'))
    // eslint-disable-next-line no-prototype-builtins
    if (window.hasOwnProperty('Xendit')) {
      const xenditKey = this.$config.XENDIT_PUBLISH_KEY
      window.Xendit.setPublishableKey(xenditKey)
    } else {
      console.warn('Loading Xendit library')
    }

    // GA tracking
    this.$trackPageView()
    // End GA tracking

    console.groupEnd('-- END pay MOUNTED --')
  },
  methods: {
    ...mapActions({
      createTransaction: 'payment/createTransaction',
      getTransaction: 'payment/getTransaction',
      preUpSert: 'cart/preUpSert',
      voucherValidate: 'voucher/validate',
      codeValidation: 'voucher/codeValidation',
      redemptionPromotion: 'promotion/redemption',
      stackRedemptions: 'redemption/stackRedemptions',
      getCampaign: 'campaign/get',
      validatePPO: 'ppo/validatePPO',
      getShopper: 'shopper/get',
      deletePayment: 'shopper/deletePayment',
      setDefaultPayment: 'shopper/setDefaultPayment',
      groupRecheckRate: 'shipping/groupRecheckRate',
      checkWorkingHours: 'shipping/checkWorkingHours',
      confirmPay: 'payment/confirmPay',
      cancelPay: 'payment/cancelPay',
      confirmContinuePay: 'payment/confirmContinuePay',
    }),
    ...mapMutations({
      setState: 'set',
      setPayment: 'payment/set',
      resetValidPromotion: 'promotion/resetValidate',
      setPromotion: 'promotion/set',
      setVoucher: 'voucher/set',
      setOrder: 'order/set',
      resetCartPlatform: 'cart/resetCartPlatform',
      showHideModalBottom: 'showHideModalBottom',
      setPPO: 'ppo/set',
    }),
  },
}
