export default function ({ $axios, store, $toast }) {
  $axios.defaults.timeout = 30000 // 30 seconds
  // console.log('$axios.defaults:', $axios.defaults)
  $axios.onRequest((config) => {
    // console.log('onRequest config:', config)

    // for local dev: bypassing protection if using ngrok and localtunnel URL proxy
    config.headers.common[
      'ngrok-skip-browser-warning'
    ] = `8PXGP8XYjY8UEFgHKkhV6ywqtLXWPRBF`
    config.headers.common[
      'Bypass-Tunnel-Reminder'
    ] = `WaKgrjmpHvpL39TbFbVtWHYgHnckkkL3`
  })
  $axios.onError((err) => {
    console.log('$axios.onError:', err)
    const errors = err?.response?.data?.errors || []
    const errorLength = errors?.length || 0
    const incTransaction = err.config.url.includes('/transaction')
    const sendOTP = err.config.url.includes('otp/send')
    const validateOTP = err.config.url.includes('otp/validate')
    const incAddress = err.config.url.includes('shopper/address')
    const incActivation = err.config.url.includes('shopper/activate')
    const incCourierRate = err.config.url.includes('/rates')
    const incCartShopper = err.config.url.includes('/cart/shopper')
    const incCartItems = err.config.url.includes('/cart/items')
    const incLatestBalance = err.config.url.includes('/payment/latest_balance')
    const incMerchantbyUsername = err.config.url.includes(
      '/merchant/?username='
    )
    const incProductbyUsername = err.config.url.includes(
      '/merchant/?merchant_username'
    )

    if (err.response.status === 401) {
      console.error('Unauthorized, please login.')
      store.dispatch('clearauth')
    }

    if (err && incTransaction && err.response.status === 402) {
      for (let idx = 0; idx < errorLength; idx++) {
        console.error(`error ${err.response.status}: `, errors[idx])
      }
    }

    const hasPpo = store.state.ppo.product?.id

    if (err && incTransaction && hasPpo) {
      store.commit('set', { payOfferLoading: false })
    }

    if (err && incCartShopper && err.response.status === 404) {
      console.error('User has empty cart')
    }

    if (err && incCourierRate) {
      store.commit('shipping/set', {
        rateLoading: false,
      })
    }

    const condtion500OTP =
      err.response.status === 500 &&
      err?.response?.data?.errors &&
      (sendOTP || validateOTP)

    if (condtion500OTP) {
      const errors = err?.response?.data?.errors
      if (errorLength > 0) {
        for (let idx = 0; idx < errorLength; idx++) {
          console.error(`error ${err.response.status}: `, errors[idx])
        }
      }
      store.commit('otp/set', {
        OTPLoading: false,
      })
      return Promise.resolve(false)
    }

    // hide toast if merchant or product not found
    if (
      (incMerchantbyUsername || incProductbyUsername) &&
      (err.response.status === 400 || err.response.status === 500)
    ) {
      return Promise.resolve(false)
    }

    // handle common error 400
    if (
      err.response.status === 400 &&
      errorLength > 0 &&
      !incCartItems &&
      !incActivation
    ) {
      if (validateOTP || sendOTP) {
        store.commit('otp/set', {
          OTPLoading: false,
        })
      }
      if (incAddress) {
        store.commit('set', {
          btnAddrLoading: false,
        })
      }
      // show toast
      if (errorLength > 0) {
        for (let idx = 0; idx < errorLength; idx++) {
          console.log('store', store)
          console.error(`error ${err.response.status}: `, errors[idx])
          if (store.$isNodeDev()) $toast.error(errors[idx], { duration: 7000 })
        }
      }
      return Promise.resolve(false)
    }

    // handle common error 500
    if (err.response.status === 500) {
      // handle error latest_balance
      if (incLatestBalance) {
        console.error('incLatestBalance 500 : ', err)
        store.commit('shopper/resetBalance')
      }

      // show toast
      if (errorLength > 0) {
        for (let idx = 0; idx < errorLength; idx++) {
          store.commit('shipping/set', {
            rateLoading: false,
          })
          store.commit('set', {
            confirmPayLoading: false,
          })
          console.log('store', store)
          if (store.$isNodeDev()) $toast.error(errors[idx], { duration: 7000 })
        }
      }
      return Promise.resolve(false)
    }
  })
  // $axios.onResponse((res) => {
  //   console.log('onResponse:', res)
  // })
  $axios.onResponseError((res) => {
    console.log('$axios onResponseError:', res)
  })
}
