import { render, staticRenderFns } from "./PoweredFlik.vue?vue&type=template&id=57d42fb9&"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TooltipVersion: require('/runner/_work/checkout-payment/checkout-payment/components/TooltipVersion.vue').default})
