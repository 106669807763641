import HmacSHA256 from 'crypto-js/hmac-sha256'
import axiosRetry from 'axios-retry'
import axios from 'axios'

// import fakePayments from '@/fakedata/payments'

const initState = () => {
  return {
    methods: [],
    default: {},
    spayment: '',
    lastCartItems: [],
    lastTrxId: '08112022-KWZFPR',
    lastTrxStatus: 'PENDING',
    lastOrderTotal: 212,
    paymentType: 'bsi_virtualaccount',
    trxDate: 1661288989,
    qrstr: 'FlikQRc0d3',
    qrExpiry: '2024-10-25T07:04:55.18Z',
    vaNumber: '10766999923d3m0',
    vaExpiry: '2024-10-25T07:04:55.18Z',
    installmentInterval: 'full',
    payOneTime: null,
    isCodExist: false,
    loadingListInstalment: true,
    selectedInstalment: { tenure: 0 },
    installments: [],
  }
}

export const state = () => initState()

export const mutations = {
  set(state, params) {
    const keys = Object.keys(params)
    keys.forEach((key) => (state[key] = params[key]))
  },
  reset(state) {
    Object.assign(state, initState())
  },
  default(state, pars) {
    state.default = pars
  },
  sortMethods(state) {
    // sort payment methods ASC
    state.methods = state.methods.sort(function (a, b) {
      const keyA = a.order
      const keyB = b.order
      // Compare
      if (keyA < keyB) return -1
      if (keyA > keyB) return 1
      return 0
    })
  },
  resetPayOneInstallment(state) {
    state.payOneTime = null
    state.installmentInterval = 'full'
  },
  setInstallments(state, param) {
    state.installments = param
  },
  selectInstallment(state, param) {
    state.selectedInstalment = param
  },
  setLoadingInstalment(state, param) {
    state.loadingListInstalment = param
  },
  resetInstallment(state) {
    state.selectedInstalment = { tenure: 0 }
  },
}

export const getters = {
  getSPayment: (state) => {
    return state.spayment
  },
}

export const setters = {
  setSPayment: (state, newVal) => {
    console.log('newVal:', newVal)
    state.spayment = newVal
  },
}

export const actions = {
  async getMethods(ctx, prm = 'hdo=false&hdg=false') {
    // remove cookie for payment list
    const pym = this.$config.PAYMENTSVC
    await this.$axios
      .$get(`${pym}/v1/pym/list?${prm}`)
      .then((res) => {
        // console.log('getMethods Payment List res:', res)
        ctx.commit('set', {
          methods: res.data,
        })
        ctx.commit('sortMethods')
      })
      .catch((err) => {
        console.error('error', err)
      })
  },
  async getMerchantMethods(ctx, prm = 'hdo=false&hdg=false') {
    const pym = this.$config.PAYMENTSVC
    const shipType = ctx.rootState.shipType
    let param = prm
    if (shipType) param += `&courier_id=${shipType}`
    await this.$axios
      .$get(`${pym}/v1/pym/list?${param}`)
      .then((res) => {
        console.log('getMerchantMethods Payment res:', res)
        ctx.commit('set', {
          methods: res.data,
        })
        const isCodExist = res?.data.some(
          (x) => x.group_id === 'cod' && x.is_show === 'true'
        )
        if (isCodExist) ctx.commit('set', { isCodExist })
        ctx.commit('sortMethods')
      })
      .catch((err) => {
        console.error('error', err)
      })
  },
  createTransaction({ commit, rootState }, param) {
    const pym = this.$config.PAYMENTSVC
    console.log('createTransaction param:', param)
    const strBody = JSON.stringify(param)
    const signature = HmacSHA256(strBody, this.$config.XSJSX).toString()
    // cleaner way to set additional headers
    this.$axios.setHeader('X-Signature', signature)
    return this.$axios
      .$post(`${pym}/v1/transactions`, param)
      .then(async (res) => {
        // handle error 500 stuck Confirm Loading
        if (!res) {
          await commit(
            'set',
            {
              confirmPayLoading: false,
            },
            { root: true }
          )
        } else {
          return res
        }
      })
      .catch((err) => {
        let isPaymentNotActive = false
        if (err?.response?.data?.errors) {
          const errors = err?.response?.data?.errors
          if (errors.length > 0) {
            errors.forEach((el) => {
              commit(
                'order/set',
                {
                  failedReason: el,
                },
                { root: true }
              )
              if (el !== 'Payment method provided is not active.') {
                // this.$toast.error(el)
                console.error('createTransaction error: ', el)
              } else {
                isPaymentNotActive = true
              }
            })
          }
        }
        commit(
          'set',
          {
            confirmPayLoading: false,
          },
          { root: true }
        )
        // need to add a return so it doesn't stop when the payment with ewallet expires (this is happening at PPO)
        if (isPaymentNotActive) {
          return err?.response?.data
        }
      })
  },
  async getDetail(_ctx, id) {
    const pym = this.$config.PAYMENTSVC
    return await this.$axios
      .$get(`${pym}/v1/transactions/${id}`)
      .catch((err) => {
        console.error('getTransaction err:', err)
        // The first request fails
      })
  },
  async getTransaction({ commit, dispatch, rootState }, id) {
    const pym = this.$config.PAYMENTSVC
    const trx = axios.create({ baseURL: this.$config.API_URL })
    axiosRetry(trx, {
      retries: 10,
      retryDelay: () => {
        return 1500
      },
      onRetry: (retryCount, error, requestConfig) => {
        console.log('retryCount:', retryCount)
        console.log('error:', error)
        console.log('requestConfig:', requestConfig)
      },
    })
    await trx
      .get(`${pym}/v1/transactions/${id}`, {
        headers: {
          Authorization: this.$auth.strategy.token.get(),
        },
      })
      .then((res) => {
        const trx = res?.data?.data
        console.log(`getTransaction ${id}: `, trx)
        if (trx) {
          let noVA =
            trx?.payment_info?.actions?.virtual_account_number ||
            '10766999923d3m0'
          if (trx?.payment_info?.type === 'bsi_virtualaccount') {
            noVA = trx?.payment_info?.actions?.virtual_account_number.slice(4)
          }
          commit('set', {
            lastTrxStatus: trx?.payment_status || 'PENDING',
            paymentType: trx?.payment_info?.type.toUpperCase() || '',
            trxDate: trx?.CreatedAt,
            lastOrderTotal: trx?.total_amount,
            vaNumber: noVA,
          })
          if (trx?.payment_status === 'SUCCEEDED') {
            const cartRedirUrl = rootState.cart.cartRedirUrl
            if (cartRedirUrl.length > 0) {
              dispatch('order/getOrderById', rootState.order.last.id, {
                root: true,
              })
            } else {
              this.$router.push('/order-success')
            }
          }
        }
      })
      .catch((err) => {
        console.error('getTransaction err:', err)
        // The first request fails
      })
  },
  cancelPay({ commit }) {
    commit(
      'set',
      {
        confirmPayLoading: false,
        confirmContinueTrx: false,
        msgContinueTrx: '',
      },
      { root: true }
    )
  },
  async confirmPay({ dispatch, commit, rootState }) {

    const areaRestrict = await rootState.cart.areaRestrict

    if (areaRestrict) {
      this.$toast.error(
        `Some item on your cart is only available for Jadetabek. Please change address or remove the item`,
        {
          duration: 5000,
        }
      )
      return
    }

    await commit('set', { confirmPayLoading: true }, { root: true })

    const defPayment = rootState.shopper.defPayment
    if (defPayment === null) {
      this.$toast.error('Please select payment method')
      this.$router.push('/payment/change')
      await commit('set', { confirmPayLoading: false }, { root: true })
      return
    }
    /* clear shopify cart */
    if (this.$router.currentRoute.name === 'cart') {
      await commit('cart/resetCartPlatform', null, { root: true })
    }
    /* clear shopify cart */
    // this.setOrder({ failedReason: '' })
    await commit('order/set', { failedReason: '' }, { root: true })

    await dispatch('confirmContinuePay')

  },
  async confirmContinuePay({ rootState, state, dispatch, commit }) {
    console.log('confirmContinuePay')
    await commit('set', { confirmContinueTrx: false }, { root: true })

    // map states
    const buyCheckItems = await rootState.cart.checkedItems
    const defPayment = await rootState.shopper.defPayment
    const cart_id = await rootState.cart.cartId
    const notes = await rootState.cart.notes
    const totalOrder = await rootState.cart.totalAmount
    const payOneTime = await state.payOneTime
    const shipType = await rootState.shipType

    // map state

    const items = []
    for (const bc of buyCheckItems) {
      items.push({
        item_id: bc.product_id,
        qty: bc.qty,
        note: bc.options.join(','),
        name: bc.title,
        warehouse_id: bc.warehouse_id,
      })
    }

    const shipping = []
    if (shipType === 'delivery') {
      for (const sc of rootState.shipping.scourier) {
        shipping.push({
          warehouse_id: sc.warehouse_id,
          courier_id: sc.id,
          shipping_amount: sc.price,
        })
      }
    }

    if (shipType === 'pickup') {
      const selWarehouse = await rootState.warehouse.selectWarehouse
      console.log('selWarehouse:', selWarehouse)
      shipping.push({
        warehouse_id: selWarehouse.id,
        courier_id: 'pickup',
        shipping_amount: 0,
      })
    }

    // const country_code = 'ID'
    // const destination = await { ...rootState.shopper.defAddress, country_code }
    const payment_type_id = payOneTime?.id ? payOneTime?.id : defPayment.type

    const params = {
      payment_type_id,
      shipping,
      items,
      cart_id,
      // destination,
      notes,
      promotion: [],
    }

    // assign promotion from voucherify
    const validPromotion = rootState.promotion.validate
    if (
      validPromotion.valid &&
      this.$config.VOUCHERIFY_CAMP.replace(/\s+/g, '') !== ''
    ) {
      const order = validPromotion.order
      const total = Math.round(order.total_amount / 100)
      const discount = Math.round(order.total_discount_amount / 100)
      const promoVoucherify = {
        id: validPromotion.id, // promotion-tier-id
        name: validPromotion.name, // promotion-tier-id
        type: validPromotion.object,
        code: '',
        total_amount: parseInt(total),
        total_discount_amount: parseInt(discount),
        // provider: 'voucherify'
      }
      params.promotion.push(promoVoucherify)
    }
    // END assign promotion from voucherify

    // send selectedRewards
    const selectedRewards = rootState.reward.selectedRewards
    if (selectedRewards.length > 0) {
      for (const reward of selectedRewards) {
        if (reward?.applicable_amount) {
          params.promotion.push({
            id: reward.reward_id,
            name: reward.reward_name,
            mid: reward.merchant_id,
            type: 'reward',
            level: 'order',
            total_amount: parseInt(totalOrder),
            total_discount_amount: parseInt(reward.applicable_amount),
          })
        }
      }
    }
    // send selectedRewards

    // add shipping discount
    const shipDisc = rootState.shipping.shipDiscount
    if (shipDisc.length > 0) {
      // check existing promotion property
      for (const sd of shipDisc) {
        params.promotion.push({
          id: sd.id,
          mid: sd.merchant_id,
          total_discount_amount: sd.applied_amount,
          type: sd.type,
          name: sd.name,
          level: sd?.level || 'order',
        })
      }
    }
    // end add shipping discount

    // actually no voucherify voucher activated yet
    if (this.$config.VOUCHERIFY_VOUCHER === 'on') {
      const voucherRedeemables = rootState.voucher.validations.redeemables
      const valid = rootState.voucher.validations.valid
      const promoVoucherVoucherify = []
      if (valid && voucherRedeemables.length > 0) {
        const selVoucher = rootState.voucher.selVoucher
        for (const r of voucherRedeemables) {
          const vid = selVoucher.find((v) => v.code === r.id)?.id
          const totalDiscount = r.order.total_applied_discount_amount / 100
          const p = {
            total_discount_amount: totalDiscount,
            code: r.id,
            id: vid,
            type: r.object,
          }
          promoVoucherVoucherify.push(p)
        }
      }

      if (promoVoucherVoucherify.length > 0) {
        params.promotion.push(promoVoucherVoucherify)
      }
    }
    // actually no voucherify voucher activated yet

    // add store_app_id to transaction metadata
    const storeAppId = rootState.cart.storeAppId
    console.log('storeAppId:', storeAppId)
    if (storeAppId.length > 0) {
      const metadata = {
        store_app_id: storeAppId,
      }
      Object.assign(params, { metadata })
    }

    //  Product Offering
    const hasPpo = await rootState.cart.hasPpo
    const isPromoPPOActive = await rootState.config.promoPostPurchaseOffer

    console.log('hasPpo && isPromoPPOActive:', hasPpo && isPromoPPOActive)
    console.log(
      '%c-> hasPpo && isPromoPPOActive ',
      'color:#2563EB;',
      hasPpo && isPromoPPOActive
    )
    // Product Offering
    if (hasPpo && isPromoPPOActive) {
      const checkedItems = await rootState.cart.checkedItems
      const payload = await checkedItems.map((item) => {
        return {
          item_id: item.product_id,
          qty: item.qty,
        }
      })
      // await this.validatePPO(payload)
      await dispatch('ppo/validatePPO', payload, { root: true })
      const ppoData = await rootState.ppo.product
      const totalDiscountVoucher = await rootState.voucher.totalDiscount
      if (ppoData?.id) {
        await commit(
          'ppo/set',
          {
            hasAddedPromo: false,
            totalDiscount: parseInt(totalDiscountVoucher),
          },
          { root: true }
        )
        this.$router.push('/limited-offers')
        return
      }
    }
    // Product Offering

    // Card Installment
    console.log('state.selectedInstalment:', state.selectedInstalment)
    if (state.selectedInstalment?.tenure > 0) {
      const installment = await state.selectedInstalment
      Object.assign(params, { installment })
    }
    // Card Installment

    console.log('FINAL transaction params:', params)
    // await commit('set', { confirmPayLoading: false }, { root: true }) // DEBUGGING
    // return // DEBUGGING
    // eslint-disable-next-line no-unreachable
    if (payOneTime?.id.includes('cod')) {
      console.log('createCodTrx params:', params)
      await dispatch('createCodTrx', params)
    }

    if (
      payOneTime === null &&
      (defPayment.type.includes('qr') ||
        defPayment.type.includes('virtualaccount'))
    ) {
      console.log('createQrVaTrx params:', params)
      await dispatch('createQrVaTrx', params)
    }

    if (
      payOneTime === null &&
      (defPayment.type === 'ovo' ||
        defPayment.type === 'dana' ||
        defPayment.type === 'gopay' ||
        defPayment.type === 'shopeepay')
    ) {
      Object.assign(params, {
        authentication_id: defPayment.token_id,
      })
      await dispatch('createEwalletTrx', params)
    }

    if (
      payOneTime === null &&
      defPayment.type === 'card' &&
      this.$config.APP_DEV === 'api_dev'
    ) {
      console.log('hit API Xendit.card.createAuthentication')
      await dispatch('createCardTrx', params)
    } else if (payOneTime === null && defPayment.type === 'bcadebit') {
      await dispatch('createCardAuthTrx', params)
    } else if (payOneTime === null && defPayment.type === 'card') {
      await dispatch('createCardTrx', params)
    }
    console.groupEnd()
  },
  async createEwalletTrx({ dispatch, commit, rootState }, params) {
    await commit('set', { confirmPayLoading: true }, { root: true })

    const ct = await dispatch('createTransaction', params)

    if (ct) {
      const checkedItems = await rootState.cart.checkedItems
      const trx = {
        lastTrxId: ct.data.id,
        lastTrxStatus: ct.data.payment_status,
        paymentType: ct.data.payment_info.type,
        lastOrderTotal: ct.data.total_amount,
        lastCartItems: checkedItems,
      }
      console.log('createEwalletTrx ct :', ct)
      // set last payment transaction
      await commit('set', trx)
      // set last payment transaction

      if (trx.lastOrderTotal === 0 && trx.lastTrxStatus === 'SUCCEEDED') {
        this.$router.push('/order-success')
        return
      }

      const payActions = ct?.data?.payment_info?.actions
      const defPayment = await rootState.shopper.defPayment

      let redir = payActions?.desktop_web_checkout_url

      const mWebCheckoutUrl = payActions?.mobile_web_checkout_url || ''
      const mDeepLinkUrl = payActions?.mobile_deeplink_checkout_url || ''

      if (this.$device.isMobileOrTablet && mWebCheckoutUrl.length > 0) {
        redir = mWebCheckoutUrl
      }

      if (this.$device.isMobileOrTablet && mDeepLinkUrl.length > 0) {
        redir = mDeepLinkUrl
      }

      const gopayDesktop = defPayment.type === 'gopay' && this.$device.isDesktop

      if (redir) {
        // Redeem Promotion
        const voucherFlikActive = await rootState.cart.voucherFlikActive
        if (
          this.$config.VOUCHERIFY_CAMP.replace(/\s+/g, '') !== '' &&
          voucherFlikActive
        ) {
          await dispatch('redeemPromotion', trx)
        }
        // Redeem Promotion

        // End redeem Promotion
        await commit('set', { confirmPayLoading: false }, { root: true })
        window.open(redir, '_self')
      }

      if (typeof redir === 'undefined' && gopayDesktop) {
        Object.assign(trx, {
          qrstr: ct?.data?.payment_info?.actions?.qr_checkout_url,
        })
        // set last payment transaction
        await commit('set', trx)
        // set last payment transaction

        // Redeem Promotion
        const voucherFlikActive = await rootState.cart.voucherFlikActive
        if (
          this.$config.VOUCHERIFY_CAMP.replace(/\s+/g, '') !== '' &&
          voucherFlikActive
        ) {
          await dispatch('redeemPromotion', trx)
        }
        // End redeem Promotion

        await commit('set', { confirmPayLoading: false }, { root: true })
        this.$router.push('/payment/gopay-qr')
      }

      if (
        (typeof redir === 'undefined' && defPayment.type === 'ovo') ||
        defPayment.type === 'shopeepay'
      ) {
        const payType = defPayment.type
        const prevPayment = defPayment
        let payLabel = ''
        if (payType === 'ovo') payLabel = 'OVO'
        if (payType === 'shopeepay') payLabel = 'ShopeePay'

        const errMsg1 = `<div class='leading-snug'>Please use another payment method or if you want to use it unlink then relink your `
        const errMsg2 = `<a class="font-bold" href='/payment/detail/${payType}?act=unlink'> ${payLabel} here</a>`
        const errMsg3 = `</div>`

        console.log('prevPayment:', prevPayment)

        const savedPayments = await rootState.shopper.saved_payments

        const newListPayment = savedPayments.filter(
          (p) => p.type !== 'ovo' && p.type !== 'shopeepay'
        )
        console.log('newListPayment:', newListPayment)

        if (newListPayment.length > 0) {
          const lastPayIdx = newListPayment.length - 1
          const lastPayment = newListPayment.find(
            (p, idx) => idx === lastPayIdx
          )
          console.log('lastPayment:', lastPayment)

          const firstPayment = newListPayment.find((p, idx) => idx === 0)
          console.log('firstPayment:', firstPayment)

          if (lastPayment?.token_id.length > 0) {
            await dispatch('shopper/setDefaultPayment', lastPayment.token_id)
              .then((res) => {
                console.log('res:', res)
                this.$router.push(`/payment/change`)
                this.$toast.info(errMsg1 + errMsg2 + errMsg3)
              })
              .catch((err) => {
                console.error('pay setDefaultPayment err:', err)
              })
          }
        } else {
          // if user has no payment activated set to QRIS
          await dispatch('shopper/setDefaultPayment', 'qr_token').then(
            (res) => {
              this.$toast.info('Please use another payment method')
              this.$router.push(`/payment/change`)
            }
          )
        }
      }
    }

    await commit('set', { confirmPayLoading: false }, { root: true })
    console.groupEnd()
  },
  async createQrVaTrx({ commit, dispatch, rootState }, params) {
    await commit('set', { confirmPayLoading: true }, { root: true })
    const checkedItems = await rootState.cart.checkedItems

    const cTrx = await dispatch('createTransaction', params)
    console.log('createQrVaTrx cTrx:', cTrx)
    // set last payment transaction
    const trx = {
      lastOrderTotal: cTrx.data.total_amount,
      lastTrxStatus: cTrx.data.payment_status,
      lastTrxId: cTrx.data.id,
      paymentType: cTrx.data.payment_info.type,
      lastCartItems: checkedItems,
    }
    await commit('set', trx)
    // set last payment transaction

    if (trx.lastOrderTotal === 0 && trx.lastTrxStatus === 'SUCCEEDED') {
      this.$router.push('/order-success')
      return
    }

    const qrstr = cTrx?.data?.payment_info?.actions?.qr_checkout_string
    const qrExpiry = cTrx?.data?.payment_info?.actions?.qr_expiry_time
    let vaNumber = cTrx?.data?.payment_info?.actions?.virtual_account_number
    if (cTrx.data.payment_info.type === 'bsi_virtualaccount') {
      vaNumber =
        cTrx?.data?.payment_info?.actions?.virtual_account_number.slice(4)
    }
    const vaExpiry =
      cTrx?.data?.payment_info?.actions?.virtual_account_expiry_time

    if ((qrstr && qrstr.length > 0) || (vaNumber.length && vaExpiry.length)) {
      if (params.payment_type_id.includes('qr')) {
        Object.assign(trx, {
          qrstr,
          qrExpiry,
        })
      }
      if (params.payment_type_id.includes('virtualaccount')) {
        Object.assign(trx, {
          vaNumber,
          vaExpiry,
        })
      }
      console.log('trx:', trx)
      // set last payment
      await commit('set', trx)
      // set last payment

      // Redeem Promotion
      const voucherFlikActive = await rootState.cart.voucherFlikActive
      if (
        this.$config.VOUCHERIFY_CAMP.replace(/\s+/g, '') !== '' &&
        voucherFlikActive
      ) {
        await dispatch('redeemPromotion', trx)
      }
      // End redeem Promotion

      await commit('set', { confirmPayLoading: false }, { root: true })

      if (params.payment_type_id.includes('qr'))
        await this.$router.replace('/payment/qr')
      if (params.payment_type_id.includes('virtualaccount'))
        await this.$router.replace('/payment/va')
    } else {
      this.$toast.error(`createQrVaTrx failed`, { duration: 5000 })

      this.$router.push('/order-failed')
    }
    await commit('set', { confirmPayLoading: false }, { root: true })
  },
  async createCardTrx({ commit, dispatch, rootState }, params) {
    await commit(
      'set',
      { confirmPayLoading: true, cardPaymentLoading: true },
      { root: true }
    )

    await dispatch('createTransaction', params)
      .then(async (res) => {
        console.log('createTransaction res:', res)
        if (res) {
          const checkedItems = await rootState.cart.checkedItems
          // set last payment transaction
          const trx = {
            lastTrxId: res.data.id,
            lastTrxStatus: res.data.payment_status,
            lastOrderTotal: res.data.total_amount,
            paymentType: res.data.payment_info.type,
            trxDate: res.data.CreatedAt,
            lastCartItems: checkedItems,
          }
          await commit('set', trx)
          // set last payment transaction

          if (trx.lastOrderTotal === 0 && trx.lastTrxStatus === 'SUCCEEDED') {
            this.$router.push('/order-success')
            return
          }
          // Redeem Promotion
          const voucherFlikActive = await rootState.cart.voucherFlikActive
          if (
            this.$config.VOUCHERIFY_CAMP.replace(/\s+/g, '') !== '' &&
            voucherFlikActive
          ) {
            await dispatch('redeemPromotion', trx)
          }
          // End redeem Promotion
          await commit('set', { confirmPayLoading: false }, { root: true })
          if (res?.data) {
            await this.$router.push('/order-success')
          }
        }
        await commit(
          'set',
          {
            confirmPayLoading: false,
            cardPaymentLoading: false,
            showXenditModal: false,
          },
          { root: true }
        )
      })
      .catch((err) => {
        console.log('confirmPay error', err)
        commit(
          'set',
          {
            confirmPayLoading: false,
            cardPaymentLoading: false,
            showXenditModal: false,
          },
          { root: true }
        )
        // this.$router.push('/order-failed')
      })
    await commit(
      'set',
      {
        confirmPayLoading: false,
        cardPaymentLoading: false,
        showXenditModal: false,
      },
      { root: true }
    )
  },
  async createCardAuthTrx({ commit, dispatch, rootState }, params) {
    await commit('set', { confirmPayLoading: true }, { root: true })
    const defPayment = rootState.shopper.defPayment
    const totalOrder = rootState.cart.totalAmount
    await window.Xendit.card.createAuthentication(
      {
        amount: totalOrder || 15000,
        token_id: defPayment.token_id,
      },
      async (err, data) => {
        if (err) {
          // Definisikan penanganan kesalahan
          this.$toast.error(err.message, { duration: 7000 })
          await commit('set', { confirmPayLoading: false }, { root: true })
        }

        if (data.status === 'VERIFIED') {
          // Penanganan keberhasilan
          Object.assign(params, { authentication_id: data.id })

          console.log('prod confirmPay:', params)

          await commit('set', { cardPaymentLoading: true }, { root: true })

          await dispatch('createTransaction', params)
            .then(async (res) => {
              if (res) {
                console.log('createTransaction res:', res)
                await commit(
                  'set',
                  { confirmPayLoading: false },
                  { root: true }
                )
                const checkedItems = rootState.cart.checkedItems
                const trx = {
                  lastTrxId: res.data.id,
                  lastOrderTotal: res.data.total_amount,
                  paymentType: res.data.payment_info.type,
                  trxDate: res.data.CreatedAt,
                  lastCartItems: checkedItems,
                }
                await commit('set', trx)
                // Redeem Promotion
                const voucherFlikActive = await rootState.cart.voucherFlikActive
                if (
                  this.$config.VOUCHERIFY_CAMP.replace(/\s+/g, '') !== '' &&
                  voucherFlikActive
                ) {
                  await dispatch('redeemPromotion', trx, {
                    root: true,
                  })
                }
                // End redeem Promotion
                if (res?.data) await this.$router.push('/order-success')
                else await this.$router.push('/order-failed')
              }
              await commit(
                'set',
                { showXenditModal: false, cardPaymentLoading: false },
                { root: true }
              )
            })
            .catch((err) => {
              console.log('confirmPay error', err)
              commit(
                'set',
                { showXenditModal: false, cardPaymentLoading: false },
                { root: true }
              )
              // this.$router.push('/order-failed')
            })
        } else if (data.status === 'IN_REVIEW') {
          // Penanganan otentikasi (3DS)
          await commit(
            'order/set',
            { failedReason: 'Payment failed, card IN_REVIEW' },
            { root: true }
          )

          await commit('set', { authUrl: data.payer_authentication_url })
          await commit('set', { showXenditModal: true }, { root: true })
        } else if (data.status === 'FAILED') {
          await commit(
            'order/set',
            { failedReason: 'Credit/Debit card verification failed' },
            { root: true }
          )
          // Penanganan kegagalan
          this.$toast.error('Credit/Debit card verification failed', {
            duration: 7000,
          })
          await commit('set', { confirmPayLoading: false }, { root: true })
          await commit('set', { showXenditModal: false }, { root: true })
        }
      }
    )
  },
  async createCodTrx({ commit, dispatch, rootState }, params) {
    await commit('set', { confirmPayLoading: true }, { root: true })
    const cTrx = await dispatch('createTransaction', params)
    console.log('cTrx:', cTrx)
    if (cTrx?.data?.payment_status && cTrx?.data?.id?.length > 3) {
      const checkedItems = rootState.cart.checkedItems
      const trx = {
        lastOrderTotal: cTrx.data.total_amount,
        lastTrxStatus: cTrx.data.payment_status,
        lastTrxId: cTrx.data.id,
        paymentType: cTrx.data.payment_info.type,
        lastCartItems: checkedItems,
      }
      await commit('set', trx)
      if (trx.lastOrderTotal === 0 && trx.lastTrxStatus === 'SUCCEEDED') {
        this.$router.push('/order-success')
        return
      }
      // Redeem Promotion
      const voucherFlikActive = await rootState.cart.voucherFlikActive
      if (
        this.$config.VOUCHERIFY_CAMP.replace(/\s+/g, '') !== '' &&
        voucherFlikActive
      ) {
        await dispatch('redeemPromotion', trx)
      }
      // End redeem Promotion
      await commit('set', { confirmPayLoading: false }, { root: true })
      await this.$router.push('/order-success')
    } else {
      console.error('createCodTrx FAILED')
      await commit('set', { confirmPayLoading: false }, { root: true })
      await this.$router.push('/order-failed')
    }
  },
  async redeemPromotion({ commit, dispatch, rootState }) {
    console.log(
      '%c-- redeemPromotion --',
      'background:#eee;color:#000;padding:4px;'
    )

    const validPromotion = await rootState.promotion.validate
    const campaignPromotion = await rootState.campaign.detail.promotion

    if (!validPromotion.valid) {
      console.error('Promotion invalid')
      return
    }
    const pTierId = campaignPromotion.tiers[0]?.id || ''
    if (pTierId === '') {
      console.error('Promotion tier ID not found')
      return
    }

    const voucherifyOrderItems = await rootState.cart.checkedItems.map((bc) => {
      return {
        sku_id: bc.product_id,
        product_id: bc.product_id,
        price: bc.unit_price * 100,
        quantity: bc.qty,
        title: bc.title,
      }
    })

    const courier = await rootState.shipping.scourier.map((sc) => {
      return {
        warehouse_id: sc.warehouse_id,
        courier_id: sc.id,
        shipping_amount: sc.price,
      }
    })

    const shopperAcc = await rootState.shopper.account
    const defAddress = await rootState.shopper.defAddress
    const buyCheckItems = await rootState.cart.checkedItems.map((bc) => {
      return {
        item_id: bc.product_id,
        qty: bc.qty,
        note: bc.options.join(','),
        name: bc.title,
        warehouse_id: bc.warehouse_id,
      }
    })

    const paymentType = await rootState.payment.paymentType.toLowerCase()
    const lastTrxId = await rootState.payment.lastTrxId

    const param = {
      customer: {
        source_id: shopperAcc.phone,
        name: `${shopperAcc.first_name} ${shopperAcc.last_name}`,
        email: shopperAcc.email,
        phone: shopperAcc.phone,
        address: {
          city: defAddress.city,
          state: defAddress.state_province,
          line_1: defAddress.address_1,
          line_2: defAddress.address_2,
          country: defAddress.country_code,
          postal_code: defAddress.postal_code,
        },
        metadata: {
          customer_phone: shopperAcc.phone,
          customer_email: shopperAcc.email,
        },
      },
      order: {
        source_id: lastTrxId,
        amount: validPromotion.order.amount,
        items: voucherifyOrderItems,
        metadata: {
          payment_type: paymentType,
          order_items: JSON.stringify(buyCheckItems),
          courier: JSON.stringify(courier),
        },
      },
      metadata: {
        channel: 'web_checkout',
        customer_phone: shopperAcc.phone,
        customer_email: shopperAcc.email,
        customer_city: defAddress.city,
        customer_state: defAddress.state_province,
        order_id: lastTrxId,
        payment_type: paymentType,
        courier: JSON.stringify(courier),
        order_items: JSON.stringify(buyCheckItems),
      },
    }
    console.log('%c-> redemptionPromotion req ', 'color:#FF95ED;', param)
    const defMessage = 'redemption does not match validation rules'
    try {
      const req = await dispatch(
        'promotion/redemption',
        {
          id: pTierId,
          param,
        },
        { root: true }
      )

      console.log('%c-> redemptionPromotion req ', 'color:#FF95ED;', req)
      const errMessage = req.data?.error?.message || req.data?.reason || ''

      if (req?.data && req.data.campaign && req.data.valid) {
        commit('promotion/set', { redemption: req.data }, { root: true })
        commit('promotion/resetValidate', null, { root: true })
      } else {
        if (
          errMessage &&
          !errMessage.includes(defMessage) &&
          this.$config.APP_DEBUG === 'on'
        )
          console.warn(`&#9432; ${errMessage}`)
        commit('promotion/resetValidate', null, { root: true })
      }
    } catch (err) {
      console.error('error', JSON.stringify(err.response))
      const errMessage = err.data?.error?.message || err.data?.reason || ''
      if (
        errMessage &&
        !errMessage.includes(defMessage) &&
        this.$config.APP_DEBUG === 'on'
      )
        this.$toast.info(`&#9432; ${errMessage}`, {
          duration: 5000,
        })
      commit('promotion/resetValidate', null, { root: true })
    }
    console.log(
      '%c-- END redeemPromotion --',
      'background:#eee;color:#000;padding:4px;'
    )
  },
  async getCardInstallment(
    { commit, state },
    { amount, token_id, merchant_ids }
  ) {
    const pym = this.$config.PAYMENTSVC
    commit('setLoadingInstalment', true)
    return await this.$axios
      .$get(
        `${pym}/v1/pym/card-installments?amount=${amount}&token_id=${token_id}&merchant_ids=${merchant_ids}`
      )
      .catch((err) => {
        commit('setLoadingInstalment', false)
        console.error('getCardInstallment err:', err)
      })
  },
  async checkCardInstallment({ rootState, commit, dispatch }) {
    console.log('checkCardInstallment:')
    const payType = await rootState.shopper.defPayment.type
    const totalAmount = await rootState.cart.totalAmount
    if (payType === 'card' && totalAmount > 0) {
      const token_id = await rootState.shopper.defPayment.token_id
      const amount = await rootState.cart.totalAmount
      const checkedItems = await rootState.cart.checkedItems
      let merchant_ids = await rootState.cart.checkedItems[0]?.merchant_id
      if (checkedItems.length > 1) {
        const mids = []
        for (const ci of checkedItems) {
          mids.push(ci.merchant_id)
        }
        merchant_ids = mids
      }
      await dispatch('getCardInstallment', {
        amount,
        token_id,
        merchant_ids,
      })
        .then(async (res) => {
          console.log('getCardInstallment result.data:', res?.data)
          const resdata = await res?.data
          const installments = resdata?.installments || []
          commit('setInstallments', installments)
          if (installments.length === 0) {
            commit('resetInstallment')
          }
          commit('setLoadingInstalment', false)
        })
        .catch((err) => {
          console.log('getCardInstallment err:', err)
          commit('setInstallments', [])
        })
    } else {
      // reset selected installment if totalAmount <= 0
      commit('resetInstallment')
    }
  },
}
